import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const pricingDetails = {
  title: "Pay-As-You-Go",
  price: "R10",
  priceUnit: "per page",
  description: [
    "No subscription required",
    "Pay only for the pages you use",
    "Instant access to your bank statement analytics",
    "Flexible and cost-effective",
  ],
  buttonText: "Start Analyzing",
  buttonVariant: "contained",
};

export default function Pricing() {
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Pricing
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Our pricing is simple and straightforward: pay only R10 per page of
          your bank statement.
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 4,
              border: "1px solid",
              borderColor: "primary.main",
              background: "linear-gradient(#033363, #021F3B)",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  mb: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "grey.100",
                }}
              >
                <Typography component="h3" variant="h6">
                  {pricingDetails.title}
                </Typography>
                <Chip
                  icon={<AutoAwesomeIcon />}
                  label="Popular Choice"
                  size="small"
                  sx={{
                    backgroundColor: "primary.contrastText",
                    "& .MuiChip-label": {
                      color: "primary.dark",
                    },
                    "& .MuiChip-icon": {
                      color: "primary.dark",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  color: "grey.50",
                }}
              >
                <Typography component="h3" variant="h2">
                  {pricingDetails.price}
                </Typography>
                <Typography component="h3" variant="h6">
                  &nbsp; {pricingDetails.priceUnit}
                </Typography>
              </Box>
              <Divider
                sx={{
                  my: 2,
                  opacity: 0.2,
                  borderColor: "grey.500",
                }}
              />
              {pricingDetails.description.map((line) => (
                <Box
                  key={line}
                  sx={{
                    py: 1,
                    display: "flex",
                    gap: 1.5,
                    alignItems: "center",
                  }}
                >
                  <CheckCircleRoundedIcon
                    sx={{
                      width: 20,
                      color: "primary.light",
                    }}
                  />
                  <Typography
                    component="text"
                    variant="subtitle2"
                    sx={{
                      color: "grey.200",
                    }}
                  >
                    {line}
                  </Typography>
                </Box>
              ))}
            </CardContent>
            <CardActions>
              <Button
                fullWidth
                variant={pricingDetails.buttonVariant}
                component="a"
                href="/Signup"
              >
                {pricingDetails.buttonText}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
