// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC64ccS28LkiquVfwSP1vLYfKMYu3rQqLI",
  authDomain: "steady-bucks-58089.firebaseapp.com",
  projectId: "steady-bucks-58089",
  storageBucket: "steady-bucks-58089.appspot.com",
  messagingSenderId: "152969821991",
  appId: "1:152969821991:web:054903064c4d2025927302",
  measurementId: "G-17Z91ZQJYR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const provider = new GoogleAuthProvider();
export const auth = getAuth();
export const db = getFirestore(app)