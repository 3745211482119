import { useState, useEffect } from "react";
import { fetchAnalyticsData } from "../../api/DownloadAPI";
import useAuth from "../../services/firebase/useAuth";
import Status from "../../constants/Status";
import LinearBufferProgress from "../../components/progress/LinearBufferProgress";
import { Box, Button } from "@mui/material";
import { analyseData } from "../../api/AnalysisAPI";
import LinearIndeterminate from "../../components/progress/LinearIndeterminate";
import { useParams } from "react-router-dom"; // Import useParams to get the ID from the URL
import { listenToStatement } from "../../services/firebase/Database";
import DashboardHeader from "../../components/dashboard/DashboardHeader";
import DashboardCharts from "../../components/dashboard/DashboardCharts";
import CustomizedSnackbar from "../../components/snackbar/CustomizedSnackbar";
import { fetchTransactionsData } from "../../api/DownloadAPI";

function Dashboard() {
  const [progress, setProgress] = useState(0);
  const [transactionsProgress, setTransactionsProgress] = useState(0);

  const [analysisData, setAnalysisData] = useState(null);
  const [isAnalysing, setIsAnalysing] = useState(false);
  
  const [selectedStatement, setSelectedStatement] = useState(null);
  const [transactions, setTransactions] = useState([]);

  // Get the user and the selected statement id
  const { user } = useAuth();
  const { id } = useParams();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    status: "success",
  });

  // Listen to a single statement using the id
  useEffect(() => {
    if (id && user) {
      const unsubscribe = listenToStatement(id, (data) => {
        setSelectedStatement(data);
      });

      return () => unsubscribe();
    }
  }, [id, user]);

  const perfomAnalysis = async () => {
    setIsAnalysing(true);
    try {
      await analyseData(selectedStatement.key, user);
      setIsAnalysing(false);

      setSnackbarConfig({
        message: "Data analysed successfully",
        status: "success",
      });
      setOpenSnackbar(true);
    } catch (error) {
      console.log("Failed to analyse data");
      setSnackbarConfig({
        message: "Failed to analyse data. Try again later!",
        status: "error",
      });
      setOpenSnackbar(true);
    }
  };

  const getAnalysis = async () => {
    try {
      const data = await fetchAnalyticsData(
        selectedStatement,
        setProgress,
        user
      );
      if (data) {
        setAnalysisData(data);
      }
    } catch (error) {
      console.log("Failed to fetch analysis data");
      setSnackbarConfig({
        message: "Failed to fetch analysis data. Try again later!",
        status: "error",
      });
      setOpenSnackbar(true);
    }
  };

  const fetchData = async () => {
    if (selectedStatement && user) {
      if (selectedStatement.status === Status.ANALYSED) {
        getAnalysis();
      } else if (selectedStatement.status === Status.DONE) {
        perfomAnalysis();
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedStatement, user]);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (
        selectedStatement &&
        (selectedStatement.status === Status.DONE ||
          selectedStatement.status === Status.ANALYSED) &&
        user
      ) {
        const data = await fetchTransactionsData(
          selectedStatement,
          setTransactionsProgress,
          user
        );
        if (data) {
          setTransactions(data);
        }
      }
    };
    fetchTransactions();
  }, [selectedStatement, setTransactions, user]);

  return (
    <Box>
      {progress !== null && progress !== 100 && !isAnalysing && (
        <LinearBufferProgress progress={progress} />
      )}
      {isAnalysing && <LinearIndeterminate />}

      <DashboardHeader selectedStatement={selectedStatement} statementId={id} />

      {/* <Button onClick={perfomAnalysis} variant="contained">Analyse</Button> */}

      {analysisData && (
        <DashboardCharts
          analysisData={analysisData}
          transactions={transactions}
          selectedStatement={selectedStatement}
        />
      )}

      <CustomizedSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarConfig={snackbarConfig}
      />
    </Box>
  );
}

export default Dashboard;
