import React, { useState, useEffect } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { getSeriesLabel } from "../../utils/FilterOptionsUtils";
import {
  processOutliersTransactions,
  filterTransactionsByType,
} from "../../utils/transactionUtils";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";

function FilteredTransactionsTable({ typeFilter, transactions }) {
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const seriesLabel = getSeriesLabel(typeFilter);
  const theme = useTheme();

  useEffect(() => {
    const filteredData = filterTransactionsByType(transactions, typeFilter);

    setFilteredTransactions(filteredData);
  }, [transactions]);

  const { rows, columns } = processOutliersTransactions(
    filteredTransactions,
    theme
  );

  return (
    <Paper elevation={1} sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h6" sx={{ m: 1 }}>
        {seriesLabel} Overview
      </Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        disableSelectionOnClick={true}
        autoHeight={true}
      />
    </Paper>
  );
}

export default FilteredTransactionsTable;
