import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { processAllTransactions } from "../../utils/transactionUtils.js";


function TransactionsTable({transactions}) {
  const theme = useTheme();

  const { rows, columns } = processAllTransactions(transactions, theme);

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
      pageSizeOptions={[10, 20]}
      disableSelectionOnClick={true}
      autoHeight={true}
    />
  );
}

export default TransactionsTable;
