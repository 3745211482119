const API = "https://1c5ezcqas7.execute-api.us-east-1.amazonaws.com/"

export const PRESIGNED_URL_ENDPOINT = `${API}presignedUrl`;

export const CHAT_API_ENDPOINT = 'https://e3ngdylt7ed4te77hmbl57ptsi0wpynd.lambda-url.us-east-1.on.aws/';

export const ANALYSIS_API_ENDPOINT = `${API}analysis`

export const SEND_EMAIL_API_ENDPOINT = 'https://kpv5vnhoacth4obd6jnxxzkola0aslyr.lambda-url.us-east-1.on.aws/'

export const CHECKOUT_API_ENDPOINT = "https://37n6lpnpk6.execute-api.us-east-1.amazonaws.com/checkout"