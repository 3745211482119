import React, { useState, useEffect } from "react";
import { getSeriesLabel } from "../../utils/FilterOptionsUtils";
import {
  processOutliersTransactions,
  getOutliers,
} from "../../utils/transactionUtils";
import { useTheme } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { Paper, Typography } from "@mui/material";

export default function OutliersTable({
  data,
  typeFilter,
  filteredTransactions,
  yearFilter,
  monthFilter,
}) {
  const theme = useTheme();

  const [outliers, setOutliers] = useState([]);

  useEffect(() => {
    if (filteredTransactions) {
      const outliers = getOutliers(
        data,
        filteredTransactions,
        typeFilter,
        yearFilter,
        monthFilter
      );
      setOutliers(outliers);
    }
  }, [filteredTransactions]);

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const { rows, columns } = processOutliersTransactions(outliers, theme);

  const seriesLabel = getSeriesLabel(typeFilter);

  return (
    <Paper elevation={1} sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h6" sx={{ m: 1 }}>
        Unusual Transactions
      </Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        disableSelectionOnClick={true}
        autoHeight={true}
      />
    </Paper>
  );
}
