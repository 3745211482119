import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../services/firebase/useAuth";
import { ButtonGroup, Fab, IconButton } from "@mui/material";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import DataUsageRoundedIcon from "@mui/icons-material/DataUsageRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import RightSettings from "./RightSettings";
import LeftNav from "./LeftNav";
import Logo from "../logo/Logo";
import Fade from "@mui/material/Fade";

const pages = [
  { name: "Statements", icon: <DocumentScannerIcon /> },
  { name: "Usage", icon: <DataUsageRoundedIcon /> },
  { name: "Support", icon: <QuestionMarkRoundedIcon /> },
];

function ApplicationBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const [selectedPage, setSelectedPage] = React.useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuth();

  React.useEffect(() => {
    // Set the selected page based on the current URL
    const currentPath = location.pathname.substring(1);
    setSelectedPage(currentPath);
  }, [location.pathname]);

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    setSelectedPage(page.name);
    navigate(`/${page.name}`);
  };

  return (
    <AppBar position="sticky" elevation={0} color="background">
      <Toolbar disableGutters>
        <Fade in timeout={3000}>
          <div>
            <IconButton
              aria-label="application logo"
              onClick={() => navigate("/")}
              sx={{
                borderRadius: "8px", // Rounded corners
              }}
            >
              <Logo
                sx={{
                  display: { xs: "none", md: "flex" },
                  mr: 1,
                  fontSize: "2rem",
                }}
              />
              <Typography
                noWrap
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "'Quicksand', sans-serif",
                  fontWeight: 700,
                  textDecoration: "none",
                }}
              >
                Steady Bucks
              </Typography>
            </IconButton>
          </div>
        </Fade>

        <LeftNav
          pages={pages}
          selectedPage={selectedPage}
          handleCloseNavMenu={handleCloseNavMenu}
          anchorElNav={anchorElNav}
          setAnchorElNav={setAnchorElNav}
        />

        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {user ? (
            <ButtonGroup variant="text" aria-label="Basic button group">
              {pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={() => handleCloseNavMenu(page)}
                  startIcon={page.icon}
                  color={selectedPage === page.name ? "info" : "inherit"}
                  size="small"
                  sx={{ px: 1 }}
                >
                  {page.name}
                </Button>
              ))}
            </ButtonGroup>
          ) : (
            <Button onClick={() => navigate("/")}>About</Button>
          )}
        </Box>
        {user && (
          <Fab
            variant="extended"
            size="small"
            color="primary"
            onClick={() => navigate("/Upload")}
            sx={{
              my: 2,
              mr: 1,
            }}
          >
            <CloudUploadRoundedIcon sx={{ mr: 1, ml: 1 }} />
            Upload
          </Fab>
        )}
        {user ? (
          <RightSettings />
        ) : (
          <Box>
            <Button
              variant="contained"
              sx={{ mr: 1 }}
              onClick={() => navigate("/Signin")}
            >
              Sign In
            </Button>
            <Button variant="contained" onClick={() => navigate("/Signup")}>
              Sign Up
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default ApplicationBar;
