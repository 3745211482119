import React, { createContext, useState, useContext, useEffect, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const CurrentThemeContext = createContext();

export const useTheme = () => useContext(CurrentThemeContext);

export const CurrentThemeProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    // Initialize the theme from local storage or default to dark theme
    const storedThemePreference = localStorage.getItem('isDarkTheme');
    return storedThemePreference !== null ? storedThemePreference === 'true' : true; // default to dark theme
  });

  useEffect(() => {
    // Save the theme preference to local storage
    localStorage.setItem('isDarkTheme', isDarkTheme);
  }, [isDarkTheme]);

  const currentTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: isDarkTheme ? "dark" : "light",
        },
      }),
    [isDarkTheme]
  );

  const toggleTheme = () => {
    setIsDarkTheme((prevIsDarkTheme) => !prevIsDarkTheme);
  };

  return (
    <CurrentThemeContext.Provider value={{ theme: isDarkTheme, toggleTheme }}>
      <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
    </CurrentThemeContext.Provider>
  );
};
