import React, { createContext, useContext, useState, useEffect } from "react";
import { listenToUser } from "../services/firebase/Database";
import useAuth from "../services/firebase/useAuth";

const UserContext = createContext();

export function useUserContext() {
  return useContext(UserContext);
}

function UserProvider({ children }) {
  const [credits, setCredits] = useState(0);
  const {user} = useAuth()

  useEffect(() => {
    if (user) {

      const unsubscribeUser = listenToUser(user.uid, (userData) => {
        if (userData) {
          setCredits(userData.credits);
        }
      });

      // Cleanup function to unsubscribe from both listeners
      return () => {
        unsubscribeUser();
      };
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        credits,
        setCredits,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
