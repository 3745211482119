import React, { useState, useEffect } from "react";
import BasicLineChart from "../charts/BasicLineChart";
import Insights from "../charts/insights/Insights";
import { Box, Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import BasicPie from "../charts/BasicPie";
import TypeGauge from "../charts/TypeGauge";
import GlobalFilters from "../charts/GlobalFilters";
import dayjs from "dayjs";
import OutliersTable from "../charts/OutliersTable";
import {
  transactionOptions,
  getYearOptions,
  getMonthOptions,
} from "../../utils/FilterOptionsUtils";
import FilteredTransactionsTable from "../charts/FilteredTransactionsTable";

function DashboardCharts({ analysisData, transactions, selectedStatement }) {
  // Define the filters for the bar chart
  const [typeFilter, setTypeFilter] = useState("money_out");
  const [yearFilter, setYearFilter] = useState("");
  const [monthFilter, setMonthFilter] = useState("");

  // Define the options for the years and months
  const [yearOptions, setYearOptions] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);

  const [filteredTransactions, setFilteredTransactions] = useState([]);

  useEffect(() => {
    const { yearOptions } = getYearOptions(analysisData.data, typeFilter);
    setYearOptions(yearOptions);

    if (yearFilter === "") {
      const years = yearOptions.map((option) => parseInt(option.value, 10)); // Extract the years from the yearOptions array
      const maxYear = Math.max(...years); // Find the maximum year
      setYearFilter(maxYear.toString());
    }
  }, [typeFilter]);

  useEffect(() => {
    if (yearFilter !== "") {
      const { monthOptions } = getMonthOptions(
        analysisData.data,
        typeFilter,
        yearFilter
      );
      setMonthOptions(monthOptions);

      // Extract valid month values as strings from monthOptions
      const validMonthValues = monthOptions.map((option) => option.value);

      if (monthFilter === "" || !validMonthValues.includes(monthFilter)) {
        const months = monthOptions.map((option) => parseInt(option.value, 10));
        const maxMonth = Math.max(...months);
        setMonthFilter(maxMonth);
      }
    }
  }, [yearFilter]);

  React.useEffect(() => {
    const filteredData = transactions.filter((item) => {
      const year = dayjs(item.transaction_date).year();
      const month = dayjs(item.transaction_date).month() + 1;

      return (
        (yearFilter === "" || year === parseInt(yearFilter)) &&
        (monthFilter === "" || month === parseInt(monthFilter))
      );
    });

    setFilteredTransactions(filteredData);
  }, [transactions, typeFilter, yearFilter, monthFilter]);

  return (
    <Box>
      <GlobalFilters
        typeFilter={typeFilter}
        setTypeFilter={setTypeFilter}
        yearFilter={yearFilter}
        setYearFilter={setYearFilter}
        monthFilter={monthFilter}
        setMonthFilter={setMonthFilter}
        transactionOptions={transactionOptions}
        yearOptions={yearOptions}
        monthOptions={monthOptions}
      />

      {analysisData && analysisData.data && (
        <Insights
          typeFilter={typeFilter}
          yearFilter={yearFilter}
          monthFilter={monthFilter}
          filteredTransactions={filteredTransactions}
          data={analysisData.data}
        />
      )}

      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid xs={12}>
          <BasicLineChart
            data={filteredTransactions}
            typeFilter={typeFilter}
            transactionOptions={transactionOptions}
            yearFilter={yearFilter}
            monthFilter={monthFilter}
          />
        </Grid>

        {analysisData && analysisData.data && (
          <Grid xs={12} md={6}>
            <TypeGauge
              data={filteredTransactions}
              typeFilter={typeFilter}
              transactionOptions={transactionOptions}
              yearFilter={yearFilter}
              monthFilter={monthFilter}
            />
          </Grid>
        )}

        {analysisData && analysisData.data && (
          <Grid xs={12} md={6}>
            <BasicPie
              data={analysisData.data}
              typeFilter={typeFilter}
              yearFilter={yearFilter}
              monthFilter={monthFilter}
            />
          </Grid>
        )}

        {analysisData && analysisData.data && (
          <Grid xs={12}>
            <OutliersTable
              typeFilter={typeFilter}
              yearFilter={yearFilter}
              monthFilter={monthFilter}
              filteredTransactions={filteredTransactions}
              data={analysisData.data}
            />
          </Grid>
        )}

        <Grid xs={12}>
          <FilteredTransactionsTable
            transactions={filteredTransactions}
            typeFilter={typeFilter}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default DashboardCharts;
