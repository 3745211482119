import { Box } from "@mui/material";
import React from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterSelect from "../charts/FilterSelect";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function GlobalFilters({
  typeFilter,
  setTypeFilter,
  yearFilter,
  setYearFilter,
  monthFilter,
  setMonthFilter,
  transactionOptions,
  yearOptions,
  monthOptions,
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        position: "sticky",
        flexWrap: "wrap",
        top: 66,
        zIndex: 1,
        my: 1,
        borderRadius: 2,
        bgcolor:
          theme.palette.mode === "light"
            ? "rgba(255, 255, 255, 0.4)"
            : "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(16px)",
        border: "1px solid",
        borderColor: "divider",
      }}
    >
      <IconButton sx={{ borderRadius: 1 }} color="info">
        <FilterAltIcon />
      </IconButton>

      <FilterSelect
        value={typeFilter}
        setValue={setTypeFilter}
        options={transactionOptions}
        label={"Type"}
      />

      <FilterSelect
        value={yearFilter}
        setValue={setYearFilter}
        options={yearOptions}
        label={"Year"}
      />

      <FilterSelect
        value={monthFilter}
        setValue={setMonthFilter}
        options={monthOptions}
        label={"Month"}
      />
    </Box>
  );
}

export default GlobalFilters;
