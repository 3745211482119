import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CloudDownloadRounded } from "@mui/icons-material";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

// Dashboard images
import dashboardFeatureDark from "../../../assets/images/pie_dark.png";
import dashboardFeatureLight from "../../../assets/images/pie_light.png";

// AI Chat images
import AIFeatureDark from "../../../assets/images/ai_dark.png";
import AIFeatureLight from "../../../assets/images/ai_light.png";

// Transactions Download images
import transactionsFeatureDark from "../../../assets/images/transactions_dark.png";
import transactionsFeatureLight from "../../../assets/images/transactions_light.png";

const items = [
  {
    icon: <QueryStatsIcon />,
    title: "Interactive Dashboard",
    description:
      "Transform your bank statements into interactive visualizations and gain deep insights into your financial data instantly.",
    imageLight: `url(${dashboardFeatureLight})`,
    imageDark: `url(${dashboardFeatureDark})`,
  },
  {
    icon: <AutoAwesomeRoundedIcon />,
    title: "AI Financial Advisor",
    description:
      "Leverage our real-time AI chatbot to analyze your spending habits and receive personalized recommendations for smarter financial decisions.",
    imageLight: `url(${AIFeatureLight})`,
    imageDark: `url(${AIFeatureDark})`,
  },
  {
    icon: <CloudDownloadRounded />,
    title: "Seamless Transaction Downloads",
    description:
      "Easily filter and download your transaction history in various formats, including CSV, XLSX, and JSON, for detailed financial analysis.",
    imageLight: `url(${transactionsFeatureLight})`,
    imageDark: `url(${transactionsFeatureDark})`,
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 4, sm: 4 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography
              component="h2"
              variant="h4"
              color="text.primary"
              sx={{ my: 1 }}
            >
              Product features
            </Typography>
          </div>
          <Grid
            container
            item
            gap={1}
            sx={{ display: { xs: "auto", sm: "none" } }}
          >
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                color={selectedItemIndex === index ? "info" : "default"}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: "auto", sm: "none" },
              mt: 4,
            }}
          >
            <Box
              sx={{
                backgroundImage: (theme) =>
                  theme.palette.mode === "light"
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundSize: "cover", // Use 'cover' to fill the container
                backgroundPosition: "center", // Center the image within the box
                backgroundRepeat: "no-repeat", // Prevent the image from repeating
                minHeight: 280,
              }}
            />

            <Box sx={{ px: 2, pb: 2 }}>
              <Typography
                color="text.primary"
                variant="body2"
                fontWeight="bold"
              >
                {selectedFeature.title}
              </Typography>
              <Typography
                color="text.secondary"
                variant="body2"
                sx={{ my: 0.5 }}
              >
                {selectedFeature.description}
              </Typography>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: "100%", display: { xs: "none", sm: "flex" } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant={selectedItemIndex === index ? "elevation" : ""}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: "fit-content",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    textAlign: "left",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { md: "center" },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === "light") {
                          return selectedItemIndex === index
                            ? "primary.main"
                            : "grey.300";
                        }
                        return selectedItemIndex === index
                          ? "primary.main"
                          : "grey.700";
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: "none" }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: "none", sm: "flex" }, width: "100%" }}
        >
          <Card
            variant="outlined"
            sx={{
              height: "100%",
              width: "100%",
              display: { xs: "none", sm: "flex" },
              pointerEvents: "none",
            }}
          >
            <Box
              sx={{
                m: "auto",
                width: "100%", // Adjust width as needed
                height: "100%", // Adjust height as needed
                backgroundSize: "contain", // Use 'contain' to ensure the entire image is visible
                backgroundImage: (theme) =>
                  theme.palette.mode === "light"
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundPosition: "center", // Center the image within the box
                backgroundRepeat: "no-repeat", // Prevent the image from repeating
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
