import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import { DataGrid } from "@mui/x-data-grid";
import Payment from '../../constants/Payment';
import { useTheme } from '@mui/material/styles';
import { Chip } from '@mui/material';
import { getUsageData } from '../../services/firebase/Database';
import useAuth from '../../services/firebase/useAuth';

function UsageTable() {
    const theme = useTheme();
  const [transactions, setTransactions] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const unsubscribe = getUsageData(setTransactions, user.uid);
      return () => unsubscribe();
    }
  }, [user, setTransactions]);

  // Function to format amount from cents to Rand (ZAR) with 2 decimal places
  const formatAmount = (amountInCents) => {
    if (amountInCents == null) return '';
    // Convert amount to number if it's a string
    // Convert cents to Rand and format with 2 decimal places
    const amountInRand = Number(amountInCents) / 100;
    return amountInRand.toFixed(2);
  };

  // Map the transactions array to rows
  const rows = transactions.map((transaction, index) => {
    const date = transaction.timestamp != null ? dayjs(transaction.timestamp).format('DD MMM YYYY HH:mm:ss') : '';
    const cost = formatAmount(transaction.cost);
    const pages = transaction.pages != null ? transaction.pages : '';
  
    // Return the row object
    return {
        id: transaction.id,
        date: date,
        cost: cost,
        pages: pages,
    };
  });
  
  
    const columns = [
      { field: "date", headerName: "Date", flex: 30 },
      { field: "cost", headerName: "Amount(R)", flex: 40 },
      { field: "pages", headerName: "Pages", flex: 30},
      // Add more columns as needed
    ];

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      pageSizeOptions={[10, 20]}
      autoHeight
    />
  )
}

export default UsageTable