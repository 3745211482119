import React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";

function VerifyDialog({handleClose, verifyUserEmail}) {
  return (
    <>
      <DialogTitle>Verify Email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To verify your email, click the button below. We will send a
          verification link to your email.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={verifyUserEmail} color="success">
          Send Verification Email
        </Button>
      </DialogActions>
    </>
  );
}

export default VerifyDialog;
