import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    question: 'How do I contact customer support if I have a question or issue?',
    answer: (
      <Typography variant="body2" gutterBottom>
        You can reach our customer support team by emailing{' '}
        <Link href="steady.bucks@outlook.com">steady.bucks@outlook.com</Link> or calling our
        toll-free number. We’re here to assist you promptly.
      </Typography>
    ),
  },
  {
    question: 'Can I return the product if it doesn’t meet my expectations?',
    answer: (
      <Typography variant="body2" gutterBottom>
        Absolutely! We offer a hassle-free return policy. If you’re not completely
        satisfied, you can return the product within 3 days for a full
        refund or exchange.
      </Typography>
    ),
  },
  {
    question: 'What makes your app stand out from others in the market?',
    answer: (
      <Typography variant="body2" gutterBottom>
        Our app distinguishes itself with its ability to seamlessly extract data from
        bank statements and provide instant analytics on a user-friendly dashboard.
        The integrated AI chatbot offers personalized insights and suggestions to help
        you better understand and manage your financial habits.
      </Typography>
    ),
  },
  {
    question: 'Which bank statements are supported by the app?',
    answer: (
      <Typography variant="body2" gutterBottom>
        Currently, our app supports personal Capitec Bank savings account statements.
        If your bank is not supported yet,{' '}
        <Link href="/WaitingList" underline="hover" color="primary">
          join the waiting list
        </Link>{' '}
        to get notified when additional banks are added.
      </Typography>
    ),
  },
  {
    question: 'Is there a warranty on the app, and what does it cover?',
    answer: (
      <Typography variant="body2" gutterBottom>
        Our app is continuously updated to ensure high performance and reliability.
        We stand by the quality of our service and offer support to address any issues
        you might encounter. For detailed information on updates and support, please
        contact our customer service.
      </Typography>
    ),
  },
];

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}
            >
              <Typography component="h3" variant="subtitle2">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {faq.answer}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
