import * as React from "react";
import { Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

export default function ChatIntro({handleSuggestedMessageSubmit}) {
  const suggestedQueries = [
    "Provide a summary of my finances?",
    "Where can I improve?",
    "Which financial habits are bad?",
  ];

  return (
    <Container maxWidth="md" sx={{ mb: 2 }}>
      <Typography variant="h4" gutterBottom>
        Welcome!
      </Typography>
      <Typography variant="body2" gutterBottom sx={{mb: 4}}>
      Our chatbot helps you make sense of your financial activities, use it to gain insights into your financial habits. 
      Try these suggested queries to get started:
      </Typography>

      <Grid container spacing={1} columns={12}>
        {suggestedQueries.map((selectedQuery, index) => (
          <Grid xs={4} key={index}>
            <Button
              variant="outlined"
              sx={{
                height: 100,
                width: "100%",
                textTransform: "none",
                borderRadius: "16px",
              }} // Set textTransform to none
              onClick={() => handleSuggestedMessageSubmit(selectedQuery)}
            >
              {selectedQuery}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
