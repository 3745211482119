import React from "react";
import {
  Container,
  Typography,
  TextField,
  CssBaseline,
  Button,
  FormControl,
} from "@mui/material";
import { sendUserPasswordResetEmail } from "../../services/firebase/Auth";
import { useNavigate } from "react-router-dom";
import CustomizedSnackbar from "../../components/snackbar/CustomizedSnackbar";
import Logo from "../../components/logo/Logo";

function ResetPassword() {
  const [email, setEmail] = React.useState("");
  const [verificationSent, setVerificationSent] = React.useState(false);
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarConfig, setSnackbarConfig] = React.useState({
    message: "",
    status: "success",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await sendUserPasswordResetEmail(email);
      setVerificationSent(true);
      setSnackbarConfig({
        message: "Password reset email sent. Please check your inbox.",
        status: "success",
      });
      setOpenSnackbar(true);
    } catch (err) {
      setSnackbarConfig({
        message: "Error sending password email. Try again later!",
        status: "error",
      });
      setOpenSnackbar(true);
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CssBaseline />
      <Logo
        sx={{
          display: { xs: "none", md: "flex" },
          mr: 1,
          fontSize: "4rem",
        }}
      />
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Reset your password
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        {!verificationSent ? (
          <FormControl fullWidth sx={{ mt: 1 }}>
            <Button color="primary" variant="contained" type="submit">
              Reset password
            </Button>
          </FormControl>
        ) : (
          <FormControl fullWidth sx={{ mt: 1 }}>
            <Button color="primary" variant="outlined" onClick={handleSubmit}>
              Resend email
            </Button>
            <Button
              sx={{ mt: 1 }}
              color="primary"
              variant="contained"
              onClick={() => navigate("/Signin")}
            >
              Sign In
            </Button>
          </FormControl>
        )}
      </form>

      <CustomizedSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarConfig={snackbarConfig}
      />
    </Container>
  );
}

export default ResetPassword;
