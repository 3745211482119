import * as React from "react";
import { LineChart, markElementClasses } from "@mui/x-charts/LineChart";
import { Box, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import { BarChart } from "@mui/x-charts/BarChart";
import FilterSelect from "./FilterSelect";
import { getSeriesLabel, getSeriesColor } from "../../utils/FilterOptionsUtils";

const chartTypeOptions = [
  { value: "Line", label: "Line" },
  { value: "Bar", label: "Bar" },
];

export default function BasicLineChart({
  data,
  typeFilter,
  yearFilter,
  monthFilter,
}) {
  const theme = useTheme();
  const [aggregatedData, setAggregatedData] = React.useState({});
  const [chartType, setChartType] = React.useState("Line");

  React.useEffect(() => {
    const newAggregatedData = data.reduce((acc, item) => {
      const date = dayjs(item.transaction_date).format("YYYY-MM-DD");
      if (!acc[date]) {
        acc[date] = 0;
      }
      acc[date] += parseFloat(item[typeFilter]);

      return acc;
    }, {});

    setAggregatedData(newAggregatedData);
  }, [data, typeFilter, yearFilter, monthFilter]);

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  // Convert aggregated data to arrays and sort by date
  const sortedDates = Object.keys(aggregatedData).sort(
    (a, b) => new Date(a) - new Date(b)
  );
  const xAxisData = sortedDates.map((date) => new Date(date));
  const seriesData = sortedDates.map((date) => {
    if (typeFilter === "balance") {
      return parseFloat(aggregatedData[date][typeFilter]);
    }
    return aggregatedData[date];
  });

  // Determine date format for axis
  const getDateFormatter = () => {
    return (date) => dayjs(date).format("D MMM YY");
  };

  const currencyFormatter = new Intl.NumberFormat("en-ZA", {
    style: "currency",
    currency: "ZAR",
  }).format;

  const seriesLabel = getSeriesLabel(typeFilter);
  const seriesColor = getSeriesColor(theme, typeFilter);

  return (
        <Paper elevation={1} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h6" sx={{ m: 1 }}>
            Daily Transactions
          </Typography>

          <Box>
            <FilterSelect
              value={chartType}
              setValue={setChartType}
              options={chartTypeOptions}
              label={"Chart"}
            />
          </Box>

          {chartType === "Line" &&
            xAxisData.length > 0 &&
            seriesData.length > 0 && (
              <LineChart
                xAxis={[
                  {
                    data: xAxisData,
                    valueFormatter: getDateFormatter(),
                  },
                ]}
                series={[
                  {
                    data: seriesData,
                    label: getSeriesLabel(),
                    color: seriesColor,
                    valueFormatter: currencyFormatter,
                  },
                ]}
                height={350}
                grid={{ horizontal: true }}
                sx={{
                  [`& .${markElementClasses.root}`]: {
                    scale: "0.5",
                    strokeWidth: 2,
                  },
                }}
              />
            )}

          {chartType === "Bar" &&
            xAxisData.length > 0 &&
            seriesData.length > 0 && (
              <BarChart
                xAxis={[
                  {
                    scaleType: "band",
                    data: xAxisData,
                    valueFormatter: getDateFormatter(),
                  },
                ]}
                series={[
                  {
                    data: seriesData,
                    valueFormatter: currencyFormatter,
                    color: seriesColor,
                  },
                ]}
                height={350}
                grid={{ horizontal: true }}
              />
            )}
        </Paper>

  );
}
