import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Status from "../../constants/Status";
import { useStatementsContext } from "../../contexts/StatementsContext";
import moment from "moment";
import { useTheme } from '@mui/material/styles';
import { Chip } from "@mui/material";


export default function StatementsTable({ statements }) {
  const { rowSelectionModel, setRowSelectionModel } = useStatementsContext();
  const theme = useTheme();

  // Map the statements array to rows
const rows = statements.map((statement, index) => {
  // Check if pages, startDate, and endDate are null
  const pages = statement.pages != null ? statement.pages : '';
  const uploadDate = statement.upload_timestamp != null ? moment.unix(statement.upload_timestamp.seconds).format("DD MMM YYYY") : '';
  const startDate = statement.startDate != null ? moment.unix(statement.startDate.seconds).format("DD MMM YYYY") : '';
  const endDate = statement.endDate != null ? moment.unix(statement.endDate.seconds).format("DD MMM YYYY") : '';

  // Combine startDate and endDate into a single dateRange field
  const dateRange = startDate && endDate ? `${startDate} - ${endDate}` : '';

  // Return the row object
  return {
      id: statement.id,
      filename: statement.filename,
      status: statement.status,
      upload_timestamp: uploadDate, // Convert Firestore Timestamp to JavaScript Date object
      pages: pages,
      dateRange: dateRange, // Combined date range
      // Add more fields as needed
  };
});

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "filename", headerName: "Filename", flex: 35 },
  {
    field: "status",
    headerName: "Status",
    flex: 30,
    renderCell: (params) => {
      let color;
      switch (params.value) {
        case Status.FAILED:
          color = "error";
          break;
        case Status.DONE:
          color = "success";
          break;
        case Status.ANALYSING:
          color = "warning";
          break;
        case Status.ANALYSED:
          color = "info";
          break;
        case Status.UPLOADING:
          color = "warning";
          break;
        case Status.PROCESSING:
          color = "warning";
          break;
        case Status.INSUFFICIENT:
          color = "error";
          break;
        default:
          color = "inherit"; // Use default color if status doesn't match
          break;
      }
      return <Chip label={params.value} variant='outlined' size='small' color={color}/>;
    },
  },
  //{ field: "pages", headerName: "Pages", flex: 10 },
  { field: "dateRange", headerName: "Date Range", flex: 35 }, // Combined date range column
  // Add more columns as needed
];


  return (
    <DataGrid
      rows={rows}
      columns={columns}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
      pageSizeOptions={[10, 20]}
      checkboxSelection
      autoHeight
      disableMultipleRowSelection={true}
    />
  );
}
