import React from 'react';
import { useLocation } from 'react-router-dom';
import ApplicationBar from './components/appbar/ApplicationBar';
import { Container, CssBaseline } from '@mui/material';

const AppWrapper = ({ children }) => {
  const location = useLocation();
  const excludePaths = ['/']; // Add other paths if needed

  const shouldShowAppBar = !excludePaths.includes(location.pathname);

  return (
    <Container>
      <CssBaseline/>
      {shouldShowAppBar && <ApplicationBar />}
      {children}
    </Container>
  );
};

export default AppWrapper;
