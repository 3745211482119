import { SEND_EMAIL_API_ENDPOINT } from "./Constants";

const sendEmail = async (user, subject, message) => {
  try {
    // Send a POST request to the Lambda Function URL
    const response = await fetch(
      `${SEND_EMAIL_API_ENDPOINT}?email=${encodeURIComponent(
        user.email
      )}&subject=${encodeURIComponent(subject)}&message=${encodeURIComponent(
        message
      )}`,
      {
        headers: {
          Authorization: user.accessToken,
        },
      }
    );

  } catch (error) {
    console.error("Error sending email:", error);
  }
};

export default sendEmail;
