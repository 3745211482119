import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Typography, Box, IconButton } from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { RefreshRounded } from "@mui/icons-material";
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

function Response({ message, isGenerating }) {
  const [isCopied, setIsCopied] = useState(false)

  const copyText = () => {
    navigator.clipboard.writeText(message.message)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%"}}>
      <Box sx={{ display: "flex" }}>
        <AutoAwesomeRoundedIcon sx={{ mt: 2 }} />
        <Typography variant="body1" sx={{ ml: 1 }}>
          <ReactMarkdown>{message.message}</ReactMarkdown>
          {isGenerating && <FiberManualRecordRoundedIcon color="secondary"/>}
        </Typography>
      </Box>

      {!isGenerating && (
        <Box sx={{ display: "flex", ml: 3 }}>
          <IconButton aria-label="copy" onClick={copyText}>
            {isCopied ? <CheckRoundedIcon fontSize="small" color="success"/> : <ContentCopyRoundedIcon fontSize="small" />}
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export default Response;
