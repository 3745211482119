import React, { useState, useEffect } from "react";
import { Box, Typography, Paper } from "@mui/material";
import FilterSelect from "./FilterSelect";
import { Chart } from "react-google-charts";
import { useTheme } from "@emotion/react";
import {
  getSeriesLabel,
  getCategoryOptions,
} from "../../utils/FilterOptionsUtils";

export default function BasicPie({
  data,
  typeFilter,
  yearFilter,
  monthFilter,
}) {
  const [categoryFilter, setCategoryFilter] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    if (yearFilter && monthFilter) {
      const categories = getCategoryOptions(
        data,
        typeFilter,
        yearFilter,
        monthFilter
      );

      setCategoryOptions(categories);

      // Reset category filter if the current category is not available
      if (!categories.some((cat) => cat.value === categoryFilter)) {
        setCategoryFilter(categories.length > 0 ? categories[0].value : "");
      }
    }
  }, [typeFilter, yearFilter, monthFilter, data]);

  if (!data) {
    return <div>No data available</div>;
  }

  // Ensure filteredData is defined and is an array
  const filteredData = (
    data[typeFilter]?.[yearFilter]?.[monthFilter]?.keywords || []
  ).find((item) => item.title === categoryFilter);

  const pieData = [
    ["Category", "Amount"], // Adding the header row
    ...(filteredData?.values || []).map((item) => [
      item.description,
      item.amount,
    ]),
  ];

  const options = {
    backgroundColor: "transparent",
    pieSliceTextStyle: {
      color: theme.palette.text.primary, // Use MUI theme primary text color
    },
    legend: {
      textStyle: {
        color: theme.palette.text.primary, // Use MUI theme primary text color
      },
      pagingTextStyle: {
        color: theme.palette.info.main,
      },
      scrollArrows: {
        activeColor: theme.palette.info.main,
      },
    },
    titleTextStyle: {
      color: theme.palette.text.primary, // Use MUI theme primary text color
    },
    pieSliceBorderColor: "none", // Remove borders around the slices
    chartArea: { width: "90%", height: "90%" },
  };

  const seriesLabel = getSeriesLabel(typeFilter);

  return (
    <Paper
      elevation={1}
      sx={{ display: "flex", height: 500, flexDirection: "column" }}
    >
      <Typography variant="h6" sx={{ m: 1 }}>
        Transaction Categories Breakdown
      </Typography>

      <Box>
        <FilterSelect
          value={categoryFilter}
          setValue={setCategoryFilter}
          options={categoryOptions}
          label={"Category"}
        />
      </Box>

      <Chart
        chartType="PieChart"
        data={pieData}
        options={options}
        width={"100%"}
        height={400}
      />
    </Paper>
  );
}
