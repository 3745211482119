import React from "react";
import { Paper, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AmountDisplay from "./AmountDisplay";
import Fade from "@mui/material/Fade";

function InsightCard({ insight, typeFilter }) {
  const theme = useTheme();

  return (
    <Fade in timeout={1000}>
      <div>
        <Paper
          sx={{
            p: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: 120,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {insight.title}
            </Typography>
          </Box>

          {insight.amount && insight.count && (
            <AmountDisplay
              amount={insight.amount}
              count={insight.count}
              typeFilter={typeFilter}
            />
          )}
        </Paper>
      </div>
    </Fade>
  );
}

export default InsightCard;
