import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    name: 'Sipho Nkosi',
    testimonial:
      "This app has revolutionized the way I manage my finances. Its user-friendly interface and powerful features make it indispensable for both my professional and personal financial planning.",
  },
  {
    name: 'Thandi Dlamini',
    testimonial:
      "As a small business owner, keeping track of expenses used to be a headache. This app has simplified everything, and the customer support is top-notch.",
  },
  {
    name: 'Johannes van der Merwe',
    testimonial:
      "I love how intuitive and effective this app is. It has simplified data extraction for my custom analyses, and the ability to download transactions in CSV format is fantastic.",
  },
  {
    name: 'Zanele Mbeki',
    testimonial:
      "The AI chatbot has been incredibly helpful in analyzing my spending habits and providing personalized financial advice. This app has truly transformed how I manage my finances. Highly recommended for anyone looking to gain deeper insights into their financial behavior."
  },
  
  {
    name: 'Lerato Mokoena',
    testimonial:
      "The ease of use and the insightful analytics provided by this app have been very helpful. It's great to see my financial status at a glance.",
  },
  {
    name: 'Pieter Pretorius',
    testimonial:
      "This app has really helped me to stay on top of my finances and save more money. It would be great to have a budgeting feature.",
  },
];

export default function Testimonials() {
  const theme = useTheme();

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Testimonials
        </Typography>
        <Typography variant="body1" color="text.secondary">
          See what our customers love about our app. Discover how we excel in efficiency, 
          durability, and satisfaction. Join us for quality, innovation, and reliable support.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={<Avatar />}
                  title={testimonial.name}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
