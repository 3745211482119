import React, { useState } from "react";
import StatementsTable from "../../components/statementsTable/StatementsTable";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { deleteStatements } from "../../services/firebase/Database";
import { useStatementsContext } from "../../contexts/StatementsContext";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import DeleteDialog from "../../components/dialog/DeleteDialog";
import CustomizedSnackbar from "../../components/snackbar/CustomizedSnackbar";
import Status from "../../constants/Status";
import TableChartIcon from "@mui/icons-material/TableChart";

function Statements() {
  const { rowSelectionModel, setRowSelectionModel, statements } =
    useStatementsContext();
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    status: "success",
  });

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleViewTransactions = () => {
    if (rowSelectionModel.length === 1) {
      const selected = statements.find(
        (statement) => statement.id === rowSelectionModel[0]
      );
      if (selected) {
        if (
          selected.status === Status.DONE ||
          selected.status === Status.ANALYSED
        ) {
          navigate(`/Transactions/${selected.id}`);
        } else {
          let message = `Statement is still ${selected.status}. Wait for status to be ${Status.DONE}!`;
          let status = "warning";

          if (selected.status === Status.INSUFFICIENT) {
            message =
              "You have INSUFFICIENT credits, reload credits before you continue!";
            status = "error";
          }
          setSnackbarConfig({
            message: message,
            status: status,
          });
          setOpenSnackbar(true);
        }
      } else {
        console.log("Selected statement not found.");
        setSnackbarConfig({
          message: "Selected statement not found!",
          status: "error",
        });
        setOpenSnackbar(true);
      }
    } else {
      console.log("Please select a single statement to view transactions.");
      setSnackbarConfig({
        message: "Please select a single statement to view transactions!",
        status: "error",
      });
      setOpenSnackbar(true);
    }
  };

  const handleDeleteStatements = async () => {
    try {
      // Check if there are any statements selected
      if (!rowSelectionModel.length) {
        console.warn("No statements selected for deletion.");
        return;
      }

      // Call deleteStatements function with statement IDs
      await deleteStatements(rowSelectionModel);

      console.log("Successfully deleted statements");
      setSnackbarConfig({
        message: "Successfully deleted statements",
        status: "success",
      });
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error deleting statements");
      setSnackbarConfig({
        message: "Error deleting statements. Try again later!",
        status: "error",
      });
      setOpenSnackbar(true);
    }
  };

  const handleViewAnalysis = () => {
    if (rowSelectionModel.length === 1) {
      const selected = statements.find(
        (statement) => statement.id === rowSelectionModel[0]
      );
      if (selected) {
        if (
          selected.status === Status.DONE ||
          selected.status === Status.ANALYSED
        ) {
          navigate(`/Dashboard/${selected.id}`);
        } else {
          let message = `Statement is still ${selected.status}. Wait for status to be ${Status.DONE}!`;
          let status = "warning";

          if (selected.status === Status.INSUFFICIENT) {
            message =
              "You have INSUFFICIENT credits, reload credits before you continue!";
            status = "error";
          }
          setSnackbarConfig({
            message: message,
            status: status,
          });
          setOpenSnackbar(true);
        }
      } else {
        console.warn("Selected statement not found");
        setSnackbarConfig({
          message: "Selected statement not found!",
          status: "error",
        });
        setOpenSnackbar(true);
      }
    } else {
      console.warn("Please select a single statement to view analytics.");
      setSnackbarConfig({
        message: "Please select a single statement to view analytics",
        status: "error",
      });
      setOpenSnackbar(true);
    }
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 1,
          flexDirection: { xs: "column", sm: "row" }, // Add this line
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }} component="h4">
          Statements
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={handleViewAnalysis}
            startIcon={<QueryStatsIcon />}
            disabled={rowSelectionModel.length !== 1 ? true : false}
            sx={{ mr: 1 }}
          >
            Dashboard
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={handleViewTransactions}
            startIcon={<TableChartIcon />}
            disabled={rowSelectionModel.length !== 1 ? true : false}
            sx={{ mr: 1 }}
          >
            Transactions
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="error"
            disabled={!rowSelectionModel.length ? true : false}
            startIcon={<DeleteRoundedIcon />}
            onClick={handleDialogOpen}
          >
            Delete
          </Button>
        </Box>
      </Box>
      <StatementsTable statements={statements} />
      <DeleteDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleDelete={handleDeleteStatements}
      />
      <CustomizedSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarConfig={snackbarConfig}
      />
    </Box>
  );
}

export default Statements;
