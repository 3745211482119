import React from "react";
import CustomizedSwitch from "../switch/CustomizedSwitch";
import {
  Tooltip,
  Avatar,
  Box,
  Typography,
  MenuItem,
  ListSubheader,
  IconButton,
  Menu,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import useAuth from "../../services/firebase/useAuth";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../services/firebase/Auth";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const settings = ["Account", "About", "Logout"];

function RightSettings() {
  const navigate = useNavigate();

  const { user } = useAuth();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting) => {
    setAnchorElUser(null);
    if (setting === "Logout") {
      handleUserLogout(); // Call handleUserLogout when Logout is selected
    } else if (setting === "About") {
      navigate("/");
    } else {
      navigate(`/${setting}`);
    }
  };

  const handleUserLogout = async () => {
    await handleLogout();
    navigate("/");
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
          >
            <Avatar
              alt={user && (user.displayName || user.email)}
              src={user && user.photoURL}
            />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={() => setAnchorElUser(null)}
      >
        <ListSubheader>
          {user && (
            <>
              Signed in as:{" "}
              <Typography variant="body1" component="span" fontWeight="bold">
                {user && (user.displayName || user.email)}
              </Typography>
            </>
          )}
        </ListSubheader>
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        ))}
        <MenuItem key="themeToggle">
          <CustomizedSwitch />
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default RightSettings;
