import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function FilterSelect({ value, setValue, options, label }) {
  // Handle change event
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
      <FormControl sx={{minWidth: 64, m: 1}} size='small'>
        <InputLabel id="filter-select-label">{label}</InputLabel>
        <Select
          labelId="filter-select-label"
          id="filter-select"
          value={value}
          label="Select"
          onChange={handleChange}
          sx={{
            '.MuiSelect-select': {
              padding: "4px",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}
