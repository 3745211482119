import { useEffect, useState } from "react";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { fetchTransactionsData } from "../../api/DownloadAPI";
import useAuth from "../../services/firebase/useAuth";
import LinearBufferProgress from "../../components/progress/LinearBufferProgress";
import Status from "../../constants/Status";
import { ExpandLess } from "@mui/icons-material";
import { useParams } from "react-router-dom"; // Import useParams to get the ID from the URL
import { listenToStatement } from "../../services/firebase/Database";
import {
  downloadCSV,
  downloadXLSX,
  downloadJSON,
} from "../../utils/DownloadHelper";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DateDisplay from "../../components/dateDisplay/DateDisplay";
import TransactionsTable from "../../components/transactionsTable/TransactionsTable";

export default function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const { user } = useAuth();
  const [progress, setProgress] = useState(0);
  const { id } = useParams(); // Get the statement ID from the URL

  const [selectedStatement, setSelectedStatement] = useState(null);

  // Listen to a single statement using the id
  useEffect(() => {
    if (id && user) {
      const unsubscribe = listenToStatement(id, (data) => {
        setSelectedStatement(data);
      });

      return () => unsubscribe();
    }
  }, [id, user]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        selectedStatement &&
        (selectedStatement.status === Status.DONE ||
          selectedStatement.status === Status.ANALYSED) &&
        user
      ) {
        console.log("selectedStatement", selectedStatement);
        const data = await fetchTransactionsData(
          selectedStatement,
          setProgress,
          user
        );
        if (data) {
          setTransactions(data);
        }
      }
    };
    fetchData();
  }, [selectedStatement, setTransactions, user]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFormatSelect = (format) => {
    if (format === "csv") {
      downloadCSV(transactions, selectedStatement.filename);
    } else if (format === "json") {
      downloadJSON(transactions, selectedStatement.filename);
    } else if (format === "xlsx") {
      downloadXLSX(transactions, selectedStatement.filename);
    }
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mb: 3 }}>
      {progress !== null && progress !== 100 && (
        <LinearBufferProgress progress={progress} />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 1,
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {selectedStatement && selectedStatement.filename}
          </Typography>
          <DateDisplay selectedStatement={selectedStatement} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleMenuOpen}
            aria-controls="format-menu"
            aria-haspopup="true"
            size="small"
            startIcon={
              Boolean(anchorEl) ? <ExpandLess /> : <CloudDownloadIcon />
            }
            disabled={transactions.length === 0}
          >
            Download
          </Button>
        </Box>
        <Menu
          id="format-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleFormatSelect("csv")}>CSV</MenuItem>
          <MenuItem onClick={() => handleFormatSelect("json")}>JSON</MenuItem>
          <MenuItem onClick={() => handleFormatSelect("xlsx")}>XLSX</MenuItem>
        </Menu>
      </Box>
      <TransactionsTable transactions={transactions} />
    </Box>
  );
}
