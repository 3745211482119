import React, { useEffect, useRef } from "react";
import Response from "../../components/chat/Response";
import Query from "../../components/chat/Query";
import { Container, Typography } from "@mui/material";
import Role from "../../constants/Role";
import {useTheme} from "@mui/material/styles";

function ChatMessages({
  messages,
  isGenerating,
  streamedChatResponse,
}) {
  // Automatically scroll to the bottom
  const messagesEndRef = useRef(null);
  const theme = useTheme();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, streamedChatResponse, isGenerating]);

  return (
    <Container>
      {messages.map((message) =>
        message.role === Role.USER ? (
          <Query key={message.id} message={message.message} />
        ) : (
          <Response key={message.id} message={message} isGenerating={false} />
        )
      )}

      {streamedChatResponse !== "" && isGenerating && (
        <Response
          message={{ message: streamedChatResponse }}
          isGenerating={isGenerating}
        />
      )}

      {streamedChatResponse.trim() === "" && isGenerating && (
        <Typography variant="body1" sx={{ m: 2, color: theme.palette.info.main }}>
          Generating response...
        </Typography>
      )}
      <div ref={messagesEndRef} />
    </Container>
  );
}

export default ChatMessages;
