export function getInsights(data, filteredTransactions, type, year, month) {
    const keywordsData = data[type]?.[year]?.[month]?.keywords || [];
  
    const insights = keywordsData.map((keyword) => {
      const totalAmount = keyword.values.reduce((sum, item) => sum + item.amount, 0);
      const totalCount = keyword.values.reduce((sum, item) => sum + item.count, 0);
  
      return {
        title: keyword.title,
        amount: totalAmount,
        count: totalCount,
      };
    });
  
    console.log(insights)
    return insights;
  }
  