import "./App.css";
import { CurrentThemeProvider } from "./contexts/ThemeContext";
import LandingPage from "./pages/landing/LandingPage";
import SignIn from "./pages/signin/SignIn";
import SignUp from "./pages/signup/SignUp";
import { Route, Routes, Navigate } from "react-router-dom";
import ProtectedRoute from "./services/firebase/ProtectedRoute";
import StatementsProvider from "./contexts/StatementsContext";
import UserProvider from "./contexts/UserContext";
import Usage from "./pages/usage/Usage";
import Support from "./pages/support/Support";
import Upload from "./pages/upload/Upload";
import AppWrapper from "./AppWrapper";
import Statements from "./pages/statements/Statements";
import Account from "./pages/account/Account";
import Dashboard from "./pages/dashboard/Dashboard";
import Chat from "./pages/chat/Chat";
import Transactions from "./pages/transactions/Transactions";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import WaitingList from "./pages/waitingList/WaitingList";

function App() {
  return (
    <CurrentThemeProvider>
      <UserProvider>
        <StatementsProvider>
          <Routes>
            <Route path="/" element={<LandingPage />} />

            <Route
              path="/Dashboard/:id"
              element={
                <AppWrapper>
                  <ProtectedRoute element={<Dashboard />} />
                </AppWrapper>
              }
            />

            <Route
              path="/Statements"
              element={
                <AppWrapper>
                  <ProtectedRoute element={<Statements />} />
                </AppWrapper>
              }
            />

            <Route
              path="/Transactions/:id"
              element={
                <AppWrapper>
                  <ProtectedRoute element={<Transactions />} />
                </AppWrapper>
              }
            />

            <Route
              path="/Chat/:id"
              element={
                <AppWrapper>
                  <ProtectedRoute element={<Chat />} />
                </AppWrapper>
              }
            />

            <Route path="/Signin" element={<SignIn />} />
            <Route path="/Signup" element={<SignUp />} />
            <Route path="/Reset" element={<ResetPassword />} />
            <Route path="/WaitingList" element={<WaitingList />} />
            <Route
              path="/Usage"
              element={
                <AppWrapper>
                  <ProtectedRoute element={<Usage />} />
                </AppWrapper>
              }
            />
            <Route
              path="/Support"
              element={
                <AppWrapper>
                  <ProtectedRoute element={<Support />} />
                </AppWrapper>
              }
            />
            <Route
              path="/Upload"
              element={
                <AppWrapper>
                  <ProtectedRoute element={<Upload />} />
                </AppWrapper>
              }
            />
            <Route
              path="/Account"
              element={
                <AppWrapper>
                  <ProtectedRoute element={<Account />} />
                </AppWrapper>
              }
            />
            {/* Catch-all route for any other paths */}

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </StatementsProvider>
      </UserProvider>
    </CurrentThemeProvider>
  );
}

export default App;
