const Status = {
    UPLOADING: 'UPLOADING',
    PROCESSING: 'PROCESSING',
    EXTRACTING: 'EXTRACTING',
    DONE: 'DONE',
    FAILED: 'FAILED',
    ANALYSING: 'ANALYSING',
    ANALYSED: 'ANALYSED',
    INSUFFICIENT: 'INSUFFICIENT'
  };
  
  export default Status;