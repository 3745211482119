import React from "react";
import { Typography, Box, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getSeriesColor } from "../../../utils/FilterOptionsUtils";

function AmountDisplay({ amount, count, typeFilter }) {
  const theme = useTheme();
  const seriesColor = getSeriesColor(theme, typeFilter);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mt: 1,
        width: "100%",
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Chip
          variant="contained"
          disabled
          size="small"
          label="R:"
          sx={{ mr: 1, borderRadius: 1 }}
        />
        <Typography
          variant="body1"
          sx={{
            color: seriesColor,
          }}
        >
          {amount.toFixed(2)}
        </Typography>
      </Box>
      <Chip variant="contained" size="small" label={count} sx={{ ml: 1 }} />
    </Box>
  );
}

export default AmountDisplay;
