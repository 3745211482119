import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useAuth from "../../services/firebase/useAuth";
import {
  handleLogout,
  sendVerificationEmail,
  updateDisplayName,
  updateUserPassword,
  deleteAccount,
} from "../../services/firebase/Auth";
import UpdateNameDialog from "./UpdateNameDialog";
import VerifyDialog from "./VerifyDialog";
import PasswordUpdateDialog from "./PasswordUpdateDialog";
import CustomizedSnackbar from "../snackbar/CustomizedSnackbar";
import { useNavigate } from "react-router-dom";
import AccountDeleteDialog from "./AccountDeleteDialog";

export default function AccountUpdateDialog({
  type,
  openDialog,
  setOpenDialog,
  setVerificationSent,
}) {
  const { user } = useAuth();
  const [nameUpdate, setNameUpdate] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    status: "success",
  });

  const handleClose = () => {
    setCurrentPassword("");
    setNewPassword("");
    setNameUpdate("");
    setOpenDialog(false);
  };

  const verifyUserEmail = async () => {
    if (user?.emailVerified) {
        setSnackbarConfig({
            message: "Email already verified",
            status: "warning",
        });
        setOpenSnackbar(true);
        handleClose()
        return
    };
    try {
      await sendVerificationEmail();
      setVerificationSent(true);
      setSnackbarConfig({
        message: "Verification email sent. Check your email",
        status: "warning",
      });
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarConfig({
        message: "Verification email failed to send. Try again later!",
        status: "error",
      });
      setOpenSnackbar(true);
    } finally {
      handleClose();
    }
  };

  const updateAccountName = async () => {
    try {
      const trimmedName = nameUpdate.trim();
      if (trimmedName.length > 0) {
        await updateDisplayName(trimmedName);

        setSnackbarConfig({
          message: "Successfully updated name",
          status: "success",
        });
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarConfig({
        message: "Name update failed. Try again later!",
        status: "error",
      });
      setOpenSnackbar(true);
    } finally {
      setNameUpdate("");
      handleClose();
    }
  };

  const updateAccountPassword = async () => {
    try {
      if (currentPassword.length > 0 && newPassword.length > 0) {
        await updateUserPassword(currentPassword, newPassword);
        console.log("Password updated successfully");
        await handleLogout();
        navigate("/Signin");
      }
    } catch (error) {
      console.log("Password update failed");
    } finally {
      setCurrentPassword("");
      setNewPassword("");
      handleClose();
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteAccount(currentPassword);
      console.log("Account deleted successfully");
    } catch (error) {
      console.log("Account deletion failed. Try again later!")
    } finally {
      handleClose();
      navigate("/");
    }
  };

  const renderDialogContent = () => {
    switch (type) {
      case "name":
        return (
          <UpdateNameDialog
            nameUpdate={nameUpdate}
            setNameUpdate={setNameUpdate}
            handleClose={handleClose}
            updateAccountName={updateAccountName}
          />
        );
      case "verify":
        return (
          <VerifyDialog
            handleClose={handleClose}
            verifyUserEmail={verifyUserEmail}
          />
        );
      case "password":
        return (
          <PasswordUpdateDialog
            currentPassword={currentPassword}
            setCurrentPassword={setCurrentPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            handleClose={handleClose}
            updateAccountPassword={updateAccountPassword}
          />
        );
      case "delete":
        return (
          <AccountDeleteDialog
            currentPassword={currentPassword}
            setCurrentPassword={setCurrentPassword}
            handleClose={handleClose}
            handleDeleteAccount={handleDeleteAccount}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Dialog fullWidth open={openDialog} onClose={handleClose}>
        {renderDialogContent()}
      </Dialog>
      <CustomizedSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarConfig={snackbarConfig}
      />
    </>
  );
}
