import { Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { useTheme } from '@mui/material/styles';


function DateDisplay({ selectedStatement }) {
  const formattedStartDate =
    selectedStatement?.startDate &&
    moment.unix(selectedStatement.startDate.seconds).format("DD MMM YYYY");
  const formattedEndDate =
    selectedStatement?.endDate &&
    moment.unix(selectedStatement.endDate.seconds).format("DD MMM YYYY");

    const theme = useTheme();

  return (
    <Typography variant="caption" sx={{ color: theme.palette.info.main }}>
      {formattedStartDate && formattedEndDate ? `${formattedStartDate} | ${formattedEndDate}` : ""}
    </Typography>
  );
}

export default DateDisplay;
