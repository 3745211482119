export const transactionOptions = [
  { value: "money_in", label: "Income" },
  { value: "money_out", label: "Expenses" },
];

export const getSeriesLabel = (typeFilter) => {
  const option = transactionOptions.find((opt) => opt.value === typeFilter);
  return option ? option.label : typeFilter;
};

// Define custom colors for each series
export const getSeriesColor = (theme, typeFilter) => {
  const colors = {
    money_in: theme.palette.success.main,
    money_out: theme.palette.warning.main,
  };

  return colors[typeFilter] || "inherit"; // Default color if typeFilter does not match
};

export function getYearOptions(data, type) {
  const years = new Set();

  Object.keys(data[type]).forEach((year) => {
    years.add(year);
  });

  const yearOptions = Array.from(years).map((year) => ({
    value: year,
    label: year,
  }));

  return { yearOptions };
}

export function getMonthOptions(data, type, year) {
  const months = new Set();

  Object.keys(data[type][year]).forEach((month) => {
    months.add(month);
  });

  const monthOptions = Array.from(months).map((month) => ({
    value: month,
    label: month,
  }));

  return { monthOptions };
}

export function getCategoryOptions(data, type, year, month) {
  const categories = (data[type]?.[year]?.[month]?.keywords || []).map(
    (item) => ({
      value: item.title,
      label: item.title,
    })
  );

  return categories;
}
