import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import InsightCard from "./InsightCard";
import { getInsights } from "../../../utils/InsightUtils";

const Insights = ({
  typeFilter,
  yearFilter,
  monthFilter,
  filteredTransactions,
  data,
}) => {
  const [insights, setInsights] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);

  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMd = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));

  const pageSize = isXs ? 1 : isMd ? 2 : 3; // Determine pageSize based on screen size

  // Function to handle next page click
  const handleNext = () => {
    if (insights && currentPage < Math.ceil(insights.length / pageSize) - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle previous page click
  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const insightsData = getInsights(
      data,
      filteredTransactions,
      typeFilter,
      yearFilter,
      monthFilter
    );

    setInsights(insightsData);
  }, [filteredTransactions, data]);

  useEffect(() => {
    setCurrentPage(0); // Reset to first page when insights change
  }, [insights]);

  // Check if insights is defined and is an array
  if (!Array.isArray(insights) || insights.length === 0) {
    return <div>No insights available.</div>;
  }

  // Calculate the slice of insights data to display based on currentPage
  const startIndex = currentPage * pageSize;
  const displayedInsights = insights.slice(startIndex, startIndex + pageSize);

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <IconButton
        size="small"
        color="info"
        disabled={currentPage === 0}
        onClick={handlePrev}
        sx={{ mr: 1, borderRadius: 1 }}
      >
        <ArrowBackIosRounded />
      </IconButton>
      <Grid container spacing={1} sx={{ flexGrow: 1 }}>
        {displayedInsights.map((insight, index) => (
          <Grid key={index} xs={12} sm={6} md={4}>
            {/* Ensure to handle the format of the insight value here */}
            <InsightCard
              insight={insight} // Assuming insight is already formatted
              typeFilter={typeFilter}
            />
          </Grid>
        ))}
      </Grid>
      <IconButton
        size="small"
        color="info"
        disabled={currentPage === Math.ceil(insights.length / pageSize) - 1}
        onClick={handleNext}
        sx={{ ml: 1, borderRadius: 1 }}
      >
        <ArrowForwardIosRounded />
      </IconButton>
    </Box>
  );
};

export default Insights;
