import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import useAuth from "../../services/firebase/useAuth";
import sendEmail from "../../api/SendEmailAPI";
import SupportReason from "../../components/support/SupportReason";
import CustomizedSnackbar from "../../components/snackbar/CustomizedSnackbar";
import LinearIndeterminate from "../../components/progress/LinearIndeterminate";

const radioValues = [
  {
    value: "technical_issue",
    label: "Technical issue with uploading bank statements",
  },
  { value: "analytics", label: "Problem with analytics or data insights" },
  { value: "file_download", label: "Issue with downloading data" },
  { value: "chatbot", label: "Issue with AI chatbot interaction" },
  { value: "payment", label: "Payment or billing questions" },
  { value: "feedback", label: "General feedback or suggestions" },
  { value: "other", label: "Other" },
];

function Support() {
  const { user } = useAuth();
  const [message, setMessage] = useState("");
  const [radioValue, setRadioValue] = useState("");
  const [selectionError, setSelectionError] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    status: "success",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (user) {
      if (radioValue === "") {
        setSelectionError(true);
      } else {
        try {
          const subjectObject = radioValues.find(
            (radio) => radio.value === radioValue
          );
          setIsSending(true);
          const subject = subjectObject ? subjectObject.label : "General Query";
          await sendEmail(user, subject, message);
          setMessage("");
          setRadioValue("");
          setMessageSent(true);
          setIsSending(false);

          setSnackbarConfig({
            message: "Message sent successfully, we'll get back to you soon.",
            status: "success",
          });
          setOpenSnackbar(true);
        } catch (error) {
          console.error("Error sending email:", error);
          setSnackbarConfig({
            message: "Failed to send message. Please try again later!",
            status: "error",
          });
          setOpenSnackbar(true);
          setIsSending(false);
        }
      }
    }
  };

  return (
    <Container>
      {isSending && <LinearIndeterminate />}
      <Container maxWidth="sm">
        <Typography
          variant="h5"
          align="center"
          sx={{ fontWeight: "bold" }}
          gutterBottom
        >
          Contact Support
        </Typography>
        {messageSent ? (
          <Typography variant="body1" align="center" sx={{ mt: 2 }}>
            Message sent successfully, we'll get back to you soon.
          </Typography>
        ) : (
          <Paper elevation={1} sx={{ p: 1 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SupportReason
                    radioValue={radioValue}
                    setRadioValue={setRadioValue}
                    radioValues={radioValues}
                    selectionError={selectionError}
                    setSelectionError={setSelectionError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Message"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        )}

        <CustomizedSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarConfig={snackbarConfig}
        />
      </Container>
    </Container>
  );
}

export default Support;
