import { Timestamp } from "firebase/firestore";
import Status from "../constants/Status";
import axios from "axios";
import { PRESIGNED_URL_ENDPOINT } from "./Constants";
import { addStatement } from "../services/firebase/Database";

const addToDatabase = async (user, selectedFile) => {
  try {
    // Get the current timestamp
    const currentTime = new Date().toLocaleString("en-ZA", {
      timeZone: "Africa/Johannesburg",
    });

    const newStatement = {
      filename: selectedFile.name,
      uid: user.uid,
      status: Status.UPLOADING,
      upload_timestamp: Timestamp.fromDate(new Date(currentTime)),
    };

    const statementId = await addStatement(newStatement);
    return statementId;
  } catch (error) {
    console.error("Error adding statement:", error);
  }
};

// Function to generate the presigned url with the 'key' query parameter
const getPresignedUrl = async (key, user) => {
  // GET request: presigned URL
  const response = await axios({
    method: "GET",
    url: `${PRESIGNED_URL_ENDPOINT}?key=${encodeURIComponent(
      key
    )}&type=upload`,
    headers: {
      Authorization: user.accessToken,
    }
  });
  const presignedUrl = response.data.presignedUrl;
  console.log(presignedUrl);
  return presignedUrl;
};

// Function to upload the selected file using the generated presigned url
const uploadToPresignedUrl = async (presignedUrl, selectedFile, setProgress) => {
  // Upload file to pre-signed URL
  const uploadResponse = await axios.put(presignedUrl, selectedFile, {
    headers: {
      "Content-Type": selectedFile.type,
      "Access-Control-Allow-Origin": "*",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setProgress(percentCompleted);
    },
  });
  return uploadResponse;
};

// Function to orchestrate the upload process
export const uploadFileToURL = async (user, selectedFile, setProgress, setSelectedFile) => {
  try {
    // Ensure a file is selected
    if (!selectedFile) {
      console.error("No file selected.");
      return;
    }

    if (user) {
      // Upload document to firestore
      setProgress(0)
      const statementId = await addToDatabase(user, selectedFile);

      if (!statementId) {
        return; // Exit if adding to database failed
      }

      const key = `${user.uid}/${statementId}-${selectedFile.name}`;
      console.log(key);
      const presignedUrl = await getPresignedUrl(key, user);
      const uploadResponse = await uploadToPresignedUrl(presignedUrl, selectedFile, setProgress);
      if (uploadResponse.status === 200) {
        setSelectedFile(null)
      }
    }
  } catch (error) {
    // Handle error
    console.error("Error uploading file:", error);
  }
};
