import React, { useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { useTheme } from "@mui/material/styles";
import { getSeriesLabel, getSeriesColor } from "../../utils/FilterOptionsUtils";

export default function TypeGauge({
  data,
  typeFilter,
  yearFilter,
  monthFilter,
}) {
  const theme = useTheme();
  const [percentage, setPercentage] = useState(0);

  React.useEffect(() => {
    const comparisonKey =
      typeFilter === "money_in"
        ? "money_out"
        : typeFilter === "money_out"
        ? "money_in"
        : "balance"; // Adjust this as necessary for your balance logic

    const aggregatedValues = data.reduce(
      (acc, item) => {
        acc.totalKey += parseFloat(item[typeFilter]);
        acc.totalComparisonKey += parseFloat(item[comparisonKey]);
        return acc;
      },
      { totalKey: 0, totalComparisonKey: 0 }
    );

    const totalSum =
      aggregatedValues.totalKey + aggregatedValues.totalComparisonKey;
    const percentage =
      totalSum !== 0
        ? Math.round((aggregatedValues.totalKey / totalSum) * 100)
        : 0; // Avoid division by zero

    setPercentage(percentage);

    //setAggregatedData(newAggregatedData); // Commented out as not used in this context
  }, [data, typeFilter, yearFilter, monthFilter]);

  if (!data) {
    return <div>No data available</div>;
  }

  const seriesLabel = getSeriesLabel(typeFilter);
  const seriesColor = getSeriesColor(theme, typeFilter);

  return (
        <Paper
          elevation={1}
          sx={{ display: "flex", height: 500, flexDirection: "column" }}
        >
          <Typography variant="h6" sx={{ m: 1 }}>
            {seriesLabel} Percentage
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Gauge
              width={400}
              height={400}
              value={percentage}
              startAngle={-90}
              endAngle={90}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 40,
                  transform: "translate(0px, 0px)",
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: seriesColor,
                },
              }}
              text={({ value, valueMax }) => `${value}%`}
            />
          </Box>
        </Paper>
  );
}
