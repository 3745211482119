import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DataUsageRoundedIcon from '@mui/icons-material/DataUsageRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';

const items = [
  {
    icon: <DashboardRoundedIcon />,
    title: 'Instant Analytics',
    description:
      'Transform your bank statements into actionable insights with real-time analytics on your personalized dashboard.',
  },
  {
    icon: <FileDownloadRoundedIcon />,
    title: 'Seamless Data Extraction',
    description:
      'Effortlessly upload and extract data from your bank statements, with the ability to download transactions in multiple formats.',
  },
  {
    icon: <ChatRoundedIcon />,
    title: 'AI-Powered Chatbot',
    description:
      'Get personalized financial advice and insights from our intelligent chatbot, designed to help you understand and optimize your spending habits.',
  },
  {
    icon: <DataUsageRoundedIcon />,
    title: 'Smart Data Integration',
    description:
      'Our app seamlessly integrates with your bank accounts, offering a comprehensive view of your financial landscape with ease.',
  },
  {
    icon: <SupportRoundedIcon />,
    title: 'Dedicated Customer Support',
    description:
      'Rely on our responsive support team to assist you with any queries or issues, ensuring a smooth and satisfying experience.',
  },
  {
    icon: <InsightsRoundedIcon />,
    title: 'Comprehensive Financial Insights',
    description:
      'Gain deeper insights into your financial behavior with detailed reports and visualizations that help you make informed decisions.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Discover how our app revolutionizes financial management with real-time analytics, seamless data extraction, and intelligent AI support. Experience comprehensive insights and dedicated customer care all in one place.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
