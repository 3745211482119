import React from "react";
import { Paper, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

function Note() {
  const theme = useTheme();
  return (
    <Paper
      variant="outlined"
      sx={{
        padding: 2,
        width: "100%",
        borderRadius: 3,
        mb: 1,
        backgroundColor: `rgba(${theme.palette.warning.main.slice(1).match(/.{2}/g).map(hex => parseInt(hex, 16)).join(',')}, 0.5)`, // Apply a tint with transparency
      }}
    >
      <Typography variant="body2" color="text.primary" gutterBottom>
        We currently support personal Capitec Bank Savings Account statements only. Please join our waiting list
        to be notified as soon as your bank is supported.
      </Typography>
      <Link
        href="/WaitingList" // Replace with your actual waiting list URL
        underline="hover"
        color="primary"
      >
        Join the waiting list
      </Link>
    </Paper>
  );
}

export default Note;
