import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Typography,
  InputAdornment
} from "@mui/material";

function PaymentDialog({ open, handleClose, handlePayment }) {
  const pricePerPage = 10.0;
  const [amount, setAmount] = useState(pricePerPage);
  const [pages, setPages] = useState(1);
  const [error, setError] = useState("");

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (value && value < pricePerPage) {
      setError("Amount must be at least R10");
    } else {
      setError("");
    }
    const calculatedPages = Math.floor(value / pricePerPage);

    setAmount(value);
    setPages(calculatedPages);
  };

  const handlePagesChange = (e) => {
    const value = e.target.value;
    if (value) {
      const calculatedAmount = value * pricePerPage;
      if (calculatedAmount < 10) {
        setError("Total amount must be at least R10");
      } else {
        setError("");
      }
      setAmount(calculatedAmount.toFixed(2));
    } else {
      setAmount("");
    }
    setPages(value);
  };

  const handleSubmit = () => {
    // Implement your submit logic here
    console.log("Amount to be paid:", amount);
    handlePayment(amount);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Payment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the amount you want to reload in Rands or specify the number of
          pages you would like to process.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Amount (R)"
          type="number"
          fullWidth
          variant="outlined"
          value={amount}
          onChange={handleAmountChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">R</InputAdornment>,
          }}
          helperText={error ? error : 'Enter the amount in Rands'}
        />
        <TextField
          margin="dense"
          label="Number of Pages"
          type="number"
          fullWidth
          variant="outlined"
          value={pages}
          onChange={handlePagesChange}
        />
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!amount || !!error}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PaymentDialog;
