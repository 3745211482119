import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";

export default function PrivacyPolicy() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <Link
        color="text.secondary"
        underline="hover"
        onClick={handleClickOpen("paper")}
        style={{ cursor: "pointer" }}
      >
        Privacy Policy
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Privacy Policy</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <h2>Privacy Policy</h2>
            <p>Effective Date: 01 August 2024</p>

            <h3>1. Introduction</h3>
            <p>
              We are committed to protecting your personal information. This
              Privacy Policy explains how we collect, use, and safeguard your
              data when you use our app.
            </p>

            <h3>2. Information We Collect</h3>
            <p>
              We collect personal information you provide directly, such as your
              name, email address, and bank statement data. We may also collect
              usage data and technical information about your interactions with
              our app.
            </p>

            <h3>3. How We Use Your Information</h3>
            <p>
              We use your information to provide, maintain, and improve our
              services. This includes processing bank statement data, generating
              analytics, and providing support.
            </p>

            <h3>4. Data Security</h3>
            <p>
              We implement appropriate security measures to protect your
              information from unauthorized access, alteration, disclosure, or
              destruction. However, no system is completely secure, and we
              cannot guarantee absolute security.
            </p>

            <h3>5. Data Sharing</h3>
            <p>
              We do not share your personal information with third parties
              except as required by law or to provide our services. We may share
              aggregated or anonymized data for analytical purposes.
            </p>

            <h3>6. Your Rights</h3>
            <p>
              You have the right to access, correct, or delete your personal
              information. To exercise these rights, please contact us at{" "}
              <a href="steady.bucks@outlook.com">steady.bucks@outlook.com</a>.
            </p>

            <h3>7. Changes to This Policy</h3>
            <p>
              We may update this Privacy Policy from time to time. Any changes
              will be posted on this page with an updated effective date. Your
              continued use of the app after changes constitutes acceptance of
              the new policy.
            </p>

            <h3>8. Contact Us</h3>
            <p>
              If you have any questions about this Privacy Policy or our data
              practices, please contact us at{" "}
              <a href="steady.bucks@outlook.com">steady.bucks@outlook.com</a>.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
