import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function CustomizedSnackbar({
  openSnackbar,
  setOpenSnackbar,
  snackbarConfig,
}) {
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={openSnackbar}
      autoHideDuration={5000}
      onClose={handleCloseSnackbar}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity={snackbarConfig.status}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {snackbarConfig.message}
      </Alert>
    </Snackbar>
  );
}
