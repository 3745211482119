import React from "react";
import { Box, Button, Chip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Status from "../../constants/Status";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import DateDisplay from "../dateDisplay/DateDisplay";

function DashboardHeader({ selectedStatement, statementId }) {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center", // Ensure items are aligned center vertically
        mb: 1,
        flexWrap: "wrap",
      }}
    >
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {selectedStatement && selectedStatement.filename}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <DateDisplay selectedStatement={selectedStatement} />
            {selectedStatement &&
              selectedStatement.status === Status.ANALYSING && (
                <Chip
                  label={selectedStatement.status}
                  color="warning"
                  variant="outlined"
                  sx={{ mx: 2 }}
                  size="small"
                />
              )}
          </Box>
        </Box>
      </Box>
      {selectedStatement && selectedStatement.status === Status.ANALYSED && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}>
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            color="secondary"
            startIcon={<AutoAwesomeRoundedIcon />}
            onClick={() => navigate(`/Chat/${statementId}`)}
          >
            Assistant
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default DashboardHeader;
