import React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, TextField } from "@mui/material";

function UpdateNameDialog({
  nameUpdate,
  setNameUpdate,
  handleClose,
  updateAccountName,
}) {
  return (
    <>
      <DialogTitle>Update Name</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter your new name.</DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={nameUpdate}
          onChange={(e) => setNameUpdate(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={updateAccountName} color="success">
          Update
        </Button>
      </DialogActions>
    </>
  );
}

export default UpdateNameDialog;
