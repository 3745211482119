import React, { createContext, useContext, useState, useEffect } from "react";
import { getStatements } from "../services/firebase/Database";
import useAuth from "../services/firebase/useAuth";

const StatementsContext = createContext();

export function useStatementsContext() {
  return useContext(StatementsContext);
}

function StatementsProvider({ children }) {
  const [statements, setStatements] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const {user} = useAuth()

  useEffect(() => {
    if (user) {
      const unsubscribe = getStatements(setStatements, user.uid);
      return () => unsubscribe();
    }
  }, [user, setStatements]);

  return (
    <StatementsContext.Provider
      value={{
        rowSelectionModel,
        setRowSelectionModel,
        statements,
        setStatements
      }}
    >
      {children}
    </StatementsContext.Provider>
  );
}

export default StatementsProvider;
