import React from "react";
import { Box, Button, Typography } from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DateDisplay from "../dateDisplay/DateDisplay";

function ChatHeader({ selectedStatement, setOpenDialog, messagesLength }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 1,
        flexWrap: "wrap",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {selectedStatement && selectedStatement.filename}
        </Typography>
        <DateDisplay selectedStatement={selectedStatement} />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}>
        <Button
          size="small"
          startIcon={<DeleteRoundedIcon />}
          variant="outlined"
          color="error"
          sx={{ ml: 1 }}
          onClick={setOpenDialog}
          disabled={messagesLength === 0}
        >
          Clear Chat
        </Button>
      </Box>
    </Box>
  );
}

export default ChatHeader;
