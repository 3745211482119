import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import useAuth from "../../services/firebase/useAuth";
import { handleLogout } from "../../services/firebase/Auth";
import { useNavigate } from "react-router-dom";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AccountUpdateDialog from "../../components/dialog/AccountUpdateDialog";

function Account() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [verificationSent, setVerificationSent] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [type, setType] = useState("");

  const handleUserLogout = async () => {
    await handleLogout();
    navigate("/");
  };

  const handleDialogOpen = (type) => {
    setOpenDialog(true);
    setType(type);
  };

  return (
    <Container maxWidth="md" sx={{ pb: 4 }}>
      <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
        Account
      </Typography>

      <Avatar
        alt={user?.displayName}
        src={user?.photoURL}
        sx={{ width: 64, height: 64, my: 2 }}
      />

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6">Name</Typography>
      <Box>
        <Button variant="outlined" disabled sx={{ textTransform: "none" }}>
          {user?.displayName ? user?.displayName : "Add Name"}
        </Button>
        <IconButton
          size="small"
          sx={{ ml: 1 }}
          onClick={() => handleDialogOpen("name")}
        >
          <EditRoundedIcon />
        </IconButton>
      </Box>

      <Typography variant="h6" sx={{ mt: 2 }}>
        Email
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Button variant="outlined" disabled sx={{ textTransform: "lowercase" }}>
          {user?.email}
        </Button>
        <Button
        variant="outlined"
        color={user?.emailVerified ? "success" : "warning"}
        sx={{ ml: 1 }}
        onClick={() => handleDialogOpen("verify")}
        disabled={verificationSent}
      >
        {user?.emailVerified
          ? "Verified"
          : verificationSent
          ? "Verification Sent"
          : "Verify Email"}
      </Button>
      </Box>
      

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6">Password</Typography>
      <Button variant="outlined" onClick={() => handleDialogOpen("password")}>Update Password</Button>

      <Typography variant="h6" sx={{ mt: 2 }}>
        Sign Out
      </Typography>
      <Button variant="outlined" color="error" onClick={handleUserLogout}>
        Sign Out
      </Button>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h6">Delete Account</Typography>
      <Button variant="contained" color="error" onClick={() => handleDialogOpen("delete")}>
        Delete Account
      </Button>
      <AccountUpdateDialog
        type={type}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        setVerificationSent={setVerificationSent}
      />
    </Container>
  );
}

export default Account;
