import React, { useState } from "react";
import {
  Button,
  Container,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CssBaseline,
  TextField,
  IconButton,
} from "@mui/material";
import Logo from "../../components/logo/Logo";
import { useNavigate } from "react-router-dom";
import { addEmailToWaitingList } from "../../services/firebase/Database";
import CustomizedSnackbar from "../../components/snackbar/CustomizedSnackbar";

const bankMenuItems = [
  { value: "FNB", label: "FNB" },
  { value: "Absa", label: "Absa" },
  { value: "Nedbank", label: "Nedbank" },
  { value: "Standard Bank", label: "Standard Bank" },
  { value: "Bidvest Bank", label: "Bidvest Bank" },
  { value: "African Bank", label: "African Bank" },
  { value: "Investec", label: "Investec" },
  { value: "Mercantile Bank", label: "Mercantile Bank" },
  {
    value: "South African Bank of Athens",
    label: "South African Bank of Athens",
  },
  { value: "Ubank", label: "Ubank" },
  { value: "Bank of Baroda", label: "Bank of Baroda" },
  { value: "HSBC", label: "HSBC" },
  { value: "RMB Private Bank", label: "RMB Private Bank" },
  { value: "Other", label: "Other" },
];

function WaitingList() {
  const [bank, setBank] = useState("FNB");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    status: "success",
  });

  const handleBankChange = (event) => {
    setBank(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      await addEmailToWaitingList(bank, email);
      setSubmitted(true);
      setSnackbarConfig({
        message:
          "Email added to waiting list. We'll contact you as soon as your bank is supported.",
        status: "success",
      });
      setOpenSnackbar(true);
    } else {
      console.log("Invalid email address");
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 2,
      }}
    >
      <CssBaseline />
      <IconButton
        aria-label="application logo"
        onClick={() => navigate("/")}
        sx={{
          borderRadius: "8px", // Rounded corners
        }}
      >
        <Logo
          sx={{
            display: { xs: "none", md: "flex" },
            mr: 1,
            fontSize: "3rem",
          }}
        />
        <Typography
          noWrap
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: 700,
            textDecoration: "none",
          }}
        >
          Steady Bucks
        </Typography>
      </IconButton>
      {submitted ? (
        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
          Email added to waiting list. We'll contact you as soon as your bank is
          supported.
        </Typography>
      ) : (
        <>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", mb: 2 }}
          >
            Waiting List
          </Typography>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                <InputLabel id="bank-select-label">Bank</InputLabel>
                <Select
                  labelId="bank-select-label"
                  id="bank-select"
                  value={bank}
                  label="Bank"
                  onChange={handleBankChange}
                >
                  {bankMenuItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                type="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit" // Change to type="submit"
                fullWidth
              >
                Submit
              </Button>
            </form>
          </Paper>
        </>
      )}

      <CustomizedSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarConfig={snackbarConfig}
      />
    </Container>
  );
}

export default WaitingList;
