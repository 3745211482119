import React from "react";
import { Box, InputAdornment, Container, Typography, Fab } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { alpha, styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import ChatIntro from "./ChatIntro";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 16,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#cedbe4" : "#3b4149",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    maxHeight: 128,
    width: "100%", // Set width to 100% to make it full width
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
  // Add styles for the endAdornment
  "& .MuiInputAdornment-root": {
    marginLeft: theme.spacing(2), // Add spacing to separate the icon from the input
  },
}));

function ChatInput({
  query,
  setQuery,
  handleSuggestedMessageSubmit,
  handleUserMessageSubmit,
  loading,
  messagesLength,
}) {
  // Determine whether to disable the button based on input content
  const isButtonDisabled = query.trim() === "";

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        width: "100%", // Adjust width accordingly
        bgcolor: "background.paper",
        pb: 1,
      }}
    >
      {loading || messagesLength === 0 && (
        <ChatIntro
          handleSuggestedMessageSubmit={handleSuggestedMessageSubmit}
        />
      )}
      <Container maxWidth="md">
        <FormControl variant="standard" sx={{ width: "100%" }}>
          <BootstrapInput
            placeholder="Type a message..."
            id="bootstrap-input"
            value={query}
            multiline
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            endAdornment={
              // Pass endAdornment directly to the styled component
              <InputAdornment position="end">
                <Fab
                  onClick={handleUserMessageSubmit}
                  aria-label="send chat message"
                  color="primary"
                  size="small"
                  disabled={isButtonDisabled} // Disable the button based on input content
                >
                  <SendIcon />
                </Fab>
              </InputAdornment>
            }
          />
        </FormControl>
      </Container>
      <Typography
        variant="caption"
        align="center"
        sx={{
          display: "block",
          textAlign: "center",
          mt: 1,
        }}
      >
        Responses may not always be accurate. Verify important information.
      </Typography>
    </Box>
  );
}

export default ChatInput;
