import { Box, Paper, Typography } from "@mui/material";
import React from "react";

function Query({ message }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", my: 1, width: '100%' }}>
      <Paper
        elevation={1}
        sx={{ padding: 1, borderRadius: 6, width: "70%" }}
      >
        <Typography variant="body1">{message}</Typography>
      </Paper>
    </Box>
  );
}

export default Query;
