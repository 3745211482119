import React from "react";
import { Box, Menu, IconButton, MenuItem, Typography } from "@mui/material";
import useAuth from "../../services/firebase/useAuth";
import InfoIcon from "@mui/icons-material/Info";
import Logo from "../logo/Logo";
import Fade from "@mui/material/Fade";

function LeftNav({
  pages,
  selectedPage,
  handleCloseNavMenu,
  anchorElNav,
  setAnchorElNav,
}) {
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const { user } = useAuth();

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
      <Fade in timeout={3000}>
        <div>
          <IconButton
            aria-label="application logo"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            sx={{
              borderRadius: "8px", // Rounded corners
            }}
          >
            <Logo
              sx={{
                mr: 1,
                fontSize: "2rem",
              }}
            />
            <Typography
              noWrap
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "'Quicksand', sans-serif",
                fontWeight: 700,
                textDecoration: "none",
              }}
            >
              Steady Bucks
            </Typography>
          </IconButton>
        </div>
      </Fade>

      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={() => setAnchorElNav(null)}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {user ? (
          pages.map((page) => (
            <MenuItem key={page.name} onClick={() => handleCloseNavMenu(page)}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {page.icon}
                <Typography
                  textAlign="center"
                  sx={{
                    fontWeight: selectedPage === page.name ? "bold" : "normal",
                    ml: 1,
                  }}
                >
                  {page.name}
                </Typography>
              </Box>
            </MenuItem>
          ))
        ) : (
          <MenuItem key="About" onClick={() => handleCloseNavMenu("")}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InfoIcon />
              <Typography
                textAlign="center"
                sx={{
                  ml: 1,
                }}
              >
                About
              </Typography>
            </Box>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
}

export default LeftNav;
