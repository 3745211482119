import { CHAT_API_ENDPOINT } from "./Constants";
import { addMessage } from "../services/firebase/Database";
import { Timestamp } from "firebase/firestore";
import Role from "../constants/Role";

const addMessageToDatabase = async (
  query,
  user,
  selectedStatement,
  role,
  queryId
) => {
  // Get the current timestamp
  let currentTime = new Date().toLocaleString("en-ZA", {
    timeZone: "Africa/Johannesburg",
  });
  const message = {
    uid: user.uid,
    statement_id: selectedStatement.id,
    message: query,
    role: role,
    timestamp: Timestamp.fromDate(new Date(currentTime)),
  };

  if (queryId && role === Role.ASSISTANT) {
    message.query_id = queryId;
  }

  const response = await addMessage(message);
  return response;
};

export const sendAIChatMessage = async (
  query,
  setIsGenerating,
  setQuery,
  user,
  setStreamedChatResponse,
  selectedStatement
) => {
  const queryId = await addMessageToDatabase(
    query,
    user,
    selectedStatement,
    Role.USER,
    null
  );
  setIsGenerating(true);
  setQuery("");

  let accumulatedData = "";

  try {
    // Encode the query to ensure special characters are correctly interpreted
    const encodedQuery = encodeURIComponent(query);
    const encodedKey = encodeURIComponent(selectedStatement.key);
    const encodedStatementId = encodeURIComponent(selectedStatement.id);
    const response = await fetch(
      `${CHAT_API_ENDPOINT}?query=${encodedQuery}&key=${encodedKey}&statement_id=${encodedStatementId}`,
      {
        headers: {
          Authorization: user.accessToken,
        },
      }
    );

    const reader = response.body.getReader();

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        setIsGenerating(false);
        const aiDatabaseResponse = await addMessageToDatabase(
          accumulatedData,
          user,
          selectedStatement,
          Role.ASSISTANT,
          queryId
        );
        break;
      }
      const decodedChunk = new TextDecoder().decode(value);
      accumulatedData += decodedChunk;
      setStreamedChatResponse((prevData) => prevData + decodedChunk);
    }
  } catch (error) {
    console.error("Error invoking Lambda function:", error);
  }
};