// utils/downloadHelper.js
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const filterData = (data) => {
  return data.map(({ transaction_id, ...rest }) => rest);
};

export const downloadCSV = (data, filename) => {
    const filteredData = filterData(data);
    
    // Extract headers
    const headers = Object.keys(filteredData[0]);
    
    // Create CSV content with headers
    const csvContent = [
      headers.join(","), // Add headers as the first row
      ...filteredData.map((item) =>
        Object.values(item).map((value) => `"${value}"`).join(",")
      )
    ];
    
    const csvBlob = new Blob([csvContent.join("\n")], {
      type: "text/csv;charset=utf-8;",
    });
    saveAs(csvBlob, `${filename}.csv`);
  };

export const downloadJSON = (data, filename) => {
  const filteredData = filterData(data);
  const jsonBlob = new Blob([JSON.stringify(filteredData, null, 2)], {
    type: "application/json",
  });
  saveAs(jsonBlob, `${filename}.json`);
};

export const downloadXLSX = (data, filename) => {
  const filteredData = filterData(data);
  const worksheet = XLSX.utils.json_to_sheet(filteredData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const xlsxBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([xlsxBlob], { type: "application/octet-stream" });
  saveAs(blob, `${filename}.xlsx`);
};
