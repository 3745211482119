// auth.js
import {
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  deleteUser,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, provider } from './firebaseConfig';

// Function to get user data
export const getUserData = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe(); // unsubscribe after the first invocation
        resolve(user);
      },
      reject
    );
  });
};

// Signing in with Google using Popup
export const signInWithGooglePopup = async () => {
  try {
    const response = await signInWithPopup(auth, provider);
    // Access user information
    const user = await getUserData();
    console.log("User signed in:", user);

    return response;
  } catch (error) {
    console.error("Error signing in with Google:", error.message);
    throw error;
  }
};

// Signing up with Email and Password
export const signUpWithEmailAndPassword = async (email, password) => {
  try {
    // Use await to wait for the promise to resolve
    const response = await createUserWithEmailAndPassword(auth, email, password);
    
    // Assuming you have a function to retrieve user data
    const user = await getUserData();

    console.log("User signed up:", user);
  } catch (error) {
    console.error("Error signing up with Email/Password:", error.message);
    throw error;
  }
};

// Signing in with Email and Password
export const signInWithUserEmailAndPassword = async (email, password) => {
  try {
    const response = await signInWithEmailAndPassword(auth, email, password)
    const user = await getUserData();
    console.log("User signed in:", user);
  } catch (error) {
    console.error("Error signing in with Google:", error.message);
    throw error;
  }
};

// Logic to handle user Logout
export const handleLogout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error("Error signing out:", error.message);
    return { success: false, message: "Error signing out" };
  }
};

// Send verification email
export const sendVerificationEmail = async () => {
  try {
    await sendEmailVerification(auth.currentUser);
    console.log("Verification email sent");
  } catch (error) {
    console.error("Error sending verification email:", error.message);
    throw error;
  }
};

// Reauthenticate user 
const reauthenticate = async (currentPassword) => {
  try {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      currentPassword
    );
    await reauthenticateWithCredential(auth.currentUser, credential);
    console.log("User reauthenticated successfully");
  } catch (error) {
    console.error("Error reauthenticating user:", error.message);
    throw error;
  }
};

// Update the displayName
export const updateDisplayName = async (displayName) => {
  try {
    await updateProfile(auth.currentUser, { displayName });
    console.log("Display name updated successfully");
  } catch (error) {
    console.error("Error updating display name:", error.message);
    throw error;
  }
};

// Update password
export const updateUserPassword = async (currentPassword, newPassword) => {
  try {
    // Reauthenticate the user with their current password
    await reauthenticate(currentPassword);
    await updatePassword(auth.currentUser, newPassword);
    console.log("Password updated successfully");
  } catch (error) {
    console.error("Error updating password:", error.message);
    throw error;
  }
};

// Delete Account
export const deleteAccount = async (currentPassword) => {
  try {
    // Reauthenticate the user with their current password
    await reauthenticate(currentPassword);
    await deleteUser(auth.currentUser);
    console.log("Account deleted successfully");
  } catch (error) {
    console.error("Error deleting account:", error.message);
    throw error;
  }
};

// Send password reset email
export const sendUserPasswordResetEmail = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset email sent");
  } catch (error) {
    console.error("Error sending password reset email:", error.message);
    throw error;
  }
};
