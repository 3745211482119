import { CHECKOUT_API_ENDPOINT } from "./Constants";
import axios from "axios";

// Function to checkout user credits reload
export const getCheckoutUrl = async (user, amount) => {
    // GET request: redirect URL
    const response = await axios({
      method: "POST",
      url: CHECKOUT_API_ENDPOINT,
      headers: {
        Authorization: user.accessToken,
      },
      data: {
        amount: amount
    }
    });
    const data = response.data;
    return data;
  };