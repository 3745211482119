import React, { useState, useEffect } from "react";
import { Container, Typography, Button, Box, ButtonGroup } from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";
import useAuth from "../../services/firebase/useAuth";
import { getCheckoutUrl } from "../../api/CheckoutAPI";
import PaymentDialog from "../../components/dialog/PaymentDialog";
import { useUserContext } from "../../contexts/UserContext";
import UsageTabs from "../../components/usage/UsageTabs";
import LinearIndeterminate from "../../components/progress/LinearIndeterminate";

function Usage() {
  const { credits, setCredits } = useUserContext();
  const { user } = useAuth();
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseDialog = () => {
    setOpenPaymentDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenPaymentDialog(true);
  };

  const handlePayment = async (amount) => {
    if (user) {
      try {
        setLoading(true);
        const checkoutUrl = await getCheckoutUrl(user, amount);
        window.location.href = checkoutUrl.redirectUrl;
      } catch (error) {
        console.log("Error initiating payment:", error);
        setLoading(false);
      }
    }
  };

  // Function to format amount from cents to Rand (ZAR) with 2 decimal places
  const formatAmount = (amountInCents) => {
    if (amountInCents == null) return "";
    // Convert amount to number if it's a string
    // Convert cents to Rand and format with 2 decimal places
    const amountInRand = Number(amountInCents) / 100;
    return amountInRand.toFixed(2);
  };

  return (
    <Container>
      {loading && <LinearIndeterminate/>}
      <Box sx={{ display: "flex", mb: 1, justifyContent: "space-between" }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
          Usage
        </Typography>
        <ButtonGroup size="small">
          <Button variant="outlined" color="info">
            R {formatAmount(credits)}
          </Button>
          <Button
            variant="contained"
            color="info"
            startIcon={<PaymentsIcon sx={{ mr: 1 }} />}
            onClick={handleOpenDialog}
          >
            Reload
          </Button>
        </ButtonGroup>
      </Box>
      <UsageTabs />
      <PaymentDialog
        open={openPaymentDialog}
        handleClose={handleCloseDialog}
        handlePayment={handlePayment}
      />
    </Container>
  );
}

export default Usage;
