import axios from "axios";
import { ANALYSIS_API_ENDPOINT } from "./Constants";

// Function to generate the analysis with the 'key' query parameter
export const analyseData = async (key, user) => {
    try {
        const response = await axios({
            method: "GET",
            url: `${ANALYSIS_API_ENDPOINT}?key=${encodeURIComponent(
              key
            )}`,
            headers: {
              Authorization: user.accessToken,
            }
          });
          console.log(response)
    } catch (error) {
      console.error("Data analysis failed!");
    }
  };
