import axios from "axios";
import { PRESIGNED_URL_ENDPOINT } from "./Constants";
import Status from "../constants/Status";
import csvtojson from "csvtojson";

export const fetchTransactionsData = async (
  selectedStatement,
  setProgress,
  user
) => {
  try {

    const downloadKey = selectedStatement.key;

    const response = await axios({
      method: "GET",
      url: `${PRESIGNED_URL_ENDPOINT}?key=${encodeURIComponent(
        downloadKey
      )}&type=download`,

      headers: {
        Authorization: user.accessToken,
      },
    });
    const presignedUrl = response.data.presignedUrl;
    console.log(presignedUrl)

    const csvDataResponse = await axios.get(presignedUrl, {
      responseType: "text", // Set response type to text
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        setProgress(percentCompleted); // Update progress bar value
      },
    });

    const jsonData = await csvtojson().fromString(csvDataResponse.data);
    return jsonData;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const fetchAnalyticsData = async (
  selectedStatement,
  setAnalysisProgress,
  user
) => {
  try {
    if (!selectedStatement || selectedStatement.status !== Status.ANALYSED) {
      return null;
    }

    // Get the key attribute from the statement
    const downloadKey = selectedStatement.key;

    // Perform the download using the downloadKey
    const response = await axios({
      method: "GET",
      url: `${PRESIGNED_URL_ENDPOINT}?key=${encodeURIComponent(
        downloadKey
      )}&type=analysis`,
      headers: {
        Authorization: user.accessToken,
      },
    });
    const presignedUrl = response.data.presignedUrl;
    console.log(presignedUrl); // Log the presigned URL for debugging purposes

    // Fetch JSON data from presigned URL without triggering download
    const jsonDataResponse = await axios.get(presignedUrl, {
      responseType: "json", // Set response type to JSON
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        setAnalysisProgress(percentCompleted); // Update progress bar value
      },
    });

    return jsonDataResponse.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
