import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth.js";

function ProtectedRoute({ element }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (user === null) {
    // Loading state, you may want to render a loader here
    return null;
  }
  if (!user) {
    navigate('/')
  }

  return element;
}

export default ProtectedRoute;
