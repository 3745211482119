import React, { useState } from "react";
import {
  Button,
  Container,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import useAuth from "../../services/firebase/useAuth";
import { uploadFileToURL } from "../../api/UploadAPI";
import LinearBufferProgress from "../../components/progress/LinearBufferProgress";
import { useNavigate } from "react-router-dom";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import { PDFDocument } from "pdf-lib";
import { useUserContext } from "../../contexts/UserContext";
import CustomizedSnackbar from "../../components/snackbar/CustomizedSnackbar";
import Note from "../../components/Note/Note";

const bankMenuItems = [{ value: "Capitec Bank", label: "Capitec Bank" }];

export default function Upload() {
  const [progress, setProgress] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const { credits, setCredits } = useUserContext();
  const [bank, setBank] = useState("Capitec Bank");

  const { user } = useAuth();
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    status: "success",
  });

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);

      // Read the file and extract the number of pages
      const fileReader = new FileReader();
      fileReader.onload = async function () {
        try {
          const arrayBuffer = this.result;
          const pdfDoc = await PDFDocument.load(arrayBuffer);
          const pageCount = pdfDoc.getPageCount();
          setNumPages(pageCount);
        } catch (error) {
          console.error("Error loading PDF:", error);
        }
      };
      fileReader.readAsArrayBuffer(file);
    } else {
      setSelectedFile(null);
      setNumPages(null);
      alert("Please select a valid PDF file.");
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      if (credits < numPages * 10 * 100) {
        const creditDiff = (numPages * 10 * 100 - credits) / 100;
        let creditsToReload = creditDiff;
        if (creditDiff < 10) {
          const deficit = 10 - creditDiff;
          creditsToReload += deficit;
        }
        setSnackbarConfig({
          message: `You have INSUFFICIENT credits to process document. Reload R ${creditsToReload.toFixed(
            2
          )} to proceed!`,
          status: "error",
        });
        setOpenSnackbar(true);
        return;
      }

      await uploadFileToURL(user, selectedFile, setProgress, setSelectedFile);
      navigate("/Statements");
    } else {
      alert("Please select a PDF file to upload.");
    }
  };

  const handleBankChange = (event) => {
    setBank(event.target.value);
  };

  return (
    <Container>
      {progress !== null && progress !== 100 && (
        <LinearBufferProgress progress={progress} />
      )}
      <Note/>
      <Container maxWidth="sm">
        <Typography
          variant="h5"
          align="center"
          sx={{ fontWeight: "bold" }}
          gutterBottom
        >
          Upload
        </Typography>
        <Paper elevation={1} sx={{ padding: 2, margin: "auto" }}>
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="file-input"
          />
          <FormControl size="small" sx={{mb: 1}}>
            <InputLabel id="demo-select-small-label">Bank</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={bank}
              label="Bank"
              onChange={handleBankChange}
            >
              {bankMenuItems.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <label htmlFor="file-input">
            <Button
              component="span"
              variant="outlined"
              fullWidth
              color="info"
              startIcon={<PictureAsPdfRoundedIcon />}
            >
              {selectedFile ? selectedFile.name : "Select PDF Bank Statement"}
            </Button>
          </label>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleUpload}
            disabled={!selectedFile}
            sx={{ marginTop: 2 }}
            startIcon={<CloudUploadRoundedIcon />}
          >
            Upload
          </Button>
        </Paper>
      </Container>
      <CustomizedSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarConfig={snackbarConfig}
      />
    </Container>
  );
}
