import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import { DataGrid } from "@mui/x-data-grid";
import Payment from '../../constants/Payment';
import { useTheme } from '@mui/material/styles';
import { Chip } from '@mui/material';
import { getTransactions } from '../../services/firebase/Database';
import useAuth from '../../services/firebase/useAuth';


export default function CreditsTable() {
  const theme = useTheme();
  const [transactions, setTransactions] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const unsubscribe = getTransactions(setTransactions, user.uid);
      return () => unsubscribe();
    }
  }, [user, setTransactions]);

  // Function to format amount from cents to Rand (ZAR) with 2 decimal places
  const formatAmount = (amountInCents) => {
    if (amountInCents == null) return '';
    // Convert amount to number if it's a string
    // Convert cents to Rand and format with 2 decimal places
    const amountInRand = Number(amountInCents) / 100;
    return amountInRand.toFixed(2);
  };

  // Map the transactions array to rows
  const rows = transactions.map((transaction, index) => {
    const date = transaction.timestamp != null ? dayjs(transaction.timestamp).format('DD MMM YYYY HH:mm:ss') : '';
    const amount = formatAmount(transaction.amount);
    const status = transaction.status != null ? transaction.status : '';
  
    // Return the row object
    return {
        id: transaction.id,
        date: date,
        amount: amount,
        status: status,
    };
  });
  
  
    const columns = [
      { field: "date", headerName: "Date", flex: 30 },
      { field: "amount", headerName: "Amount(R)", flex: 40 },
      { field: "status", headerName: "Status", flex: 30, renderCell: (params) => {
        let color;
        switch (params.value) {
          case Payment.PENDING:
            color = "warning";
            break;
          case Payment.COMPLETE:
            color = "success";
            break;
          default:
            color = "inherit"; // Use default color if status doesn't match
            break;
        }
        return <Chip label={params.value} variant='outlined' size='small' color={color}/>;
      }, },
      // Add more columns as needed
    ];

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      pageSizeOptions={[10, 20]}
      autoHeight
    />
  );
}
