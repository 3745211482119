import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";

export default function SupportReason({
  radioValue,
  setRadioValue,
  radioValues,
  selectionError,
  setSelectionError,
}) {
  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    setSelectionError(false);
  };

  return (
    <FormControl sx={{ m: 1}} error={selectionError} variant="standard">
      <FormLabel id="support-reason-label">Reason for Contact</FormLabel>
      <RadioGroup
        aria-labelledby="support-reason-label"
        name="contact-reason"
        value={radioValue}
        onChange={handleRadioChange}
      >
        {radioValues.map((radio) => (
          <FormControlLabel
            key={radio.value}
            value={radio.value}
            control={<Radio size="small"/>}
            label={radio.label}
          />
        ))}
      </RadioGroup>
      <FormHelperText>
        Select a reason to help us assist you better
      </FormHelperText>
    </FormControl>
  );
}
