import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";

export default function Terms() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <Link
        color="text.secondary"
        underline="hover"
        onClick={handleClickOpen("paper")}
        style={{ cursor: "pointer" }}
      >
        Terms of service
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms of Service</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <h2>Terms of Service</h2>
            <p>Effective Date: 01 August 2024</p>

            <h3>1. Acceptance of Terms</h3>
            <p>
              By using our app, you agree to these Terms of Service. If you do
              not agree to these terms, please do not use our app.
            </p>

            <h3>2. Service Description</h3>
            <p>
              Our app allows users to upload bank statements, extract data, and
              view analytics on a dashboard. It also includes a chatbot to
              provide financial insights and suggestions.
            </p>

            <h3>3. User Responsibilities</h3>
            <p>
              You are responsible for providing accurate information and
              maintaining the confidentiality of your account. You agree to use
              our app in accordance with applicable laws and regulations.
            </p>

            <h3>4. Data Privacy</h3>
            <p>
              We collect and process personal data as described in our Privacy
              Policy. By using the app, you consent to our data collection and
              use practices.
            </p>

            <h3>5. Support and Feedback</h3>
            <p>
              For any issues or questions, please contact our support team at{" "}
              <a href="steady.bucks@outlook.com">steady.bucks@outlook.com</a>.
              We appreciate your feedback to improve our service.
            </p>

            <h3>6. Termination</h3>
            <p>
              We reserve the right to suspend or terminate your access to the
              app if you violate these terms or for any other reason deemed
              necessary.
            </p>

            <h3>7. Changes to Terms</h3>
            <p>
              We may update these Terms of Service from time to time. The
              revised terms will be effective when posted on the app. Your
              continued use of the app constitutes acceptance of the updated
              terms.
            </p>

            <h3>8. Limitation of Liability</h3>
            <p>
              We are not liable for any indirect, incidental, or consequential
              damages arising from the use or inability to use our app.
            </p>

            <h3>9. Governing Law</h3>
            <p>
              These terms are governed by the laws of South Africa. Any disputes
              will be resolved in the courts of South Africa.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
