import moment from "moment";

export function getOutliers(data, filteredTransactions, type, year, month) {
  const outlierIds = data[type]?.[year]?.[month]?.outliers || [];

  return filteredTransactions.filter((transaction) =>
    outlierIds.includes(transaction.transaction_id)
  );
}

export const processAllTransactions = (transactions, theme) => {
  // Map over the transactions array to create rows dynamically
  const rows = transactions.map((transaction) => {
    const moneyIn = parseFloat(transaction.money_in);
    const moneyOut = parseFloat(transaction.money_out);
    const amount = moneyIn !== 0 ? moneyIn : -moneyOut; // Combine money in and out

    return {
      id: transaction.transaction_id,
      posting_date: moment(transaction.posting_date).format("DD MMM YYYY"),
      transaction_date: moment(transaction.transaction_date).format(
        "DD MMM YYYY"
      ),
      description: transaction.description,
      amount, // Use the combined amount
      balance: parseFloat(transaction.balance),
    };
  });

  // Define columns for the table
  const columns = [
    { field: "id", headerName: "ID", flex: 5 },
    { field: "transaction_date", headerName: "Transaction Date", flex: 25 },
    { field: "description", headerName: "Description", flex: 45 },
    {
      field: "amount",
      headerName: "Amount(R)",
      flex: 15,
      renderCell: (params) => {
        let color;
        let value;
        if (params.value > 0) {
          color = theme.palette.success.main;
          value = params.value;
        } else if (params.value < 0) {
          color = theme.palette.warning.main;
          value = params.value; // Value is already negative
        } else {
          color = "inherit";
          value = "";
        }
        return <span style={{ color }}>{value}</span>;
      },
    },
    { field: "balance", headerName: "Balance(R)", flex: 15 },
    // Add more columns as needed
  ];

  return { rows, columns };
};

export const processOutliersTransactions = (transactions, theme) => {
  // Map over the transactions array to create rows dynamically
  const rows = transactions.map((transaction) => {
    const moneyIn = parseFloat(transaction.money_in);
    const moneyOut = parseFloat(transaction.money_out);
    const amount = moneyIn !== 0 ? moneyIn : -moneyOut; // Combine money in and out

    return {
      id: transaction.transaction_id,
      posting_date: moment(transaction.posting_date).format("DD MMM YYYY"),
      transaction_date: moment(transaction.transaction_date).format(
        "DD MMM YYYY"
      ),
      description: transaction.description,
      amount, // Use the combined amount
      balance: parseFloat(transaction.balance),
    };
  });

  // Define columns for the table
  const columns = [
    { field: "id", headerName: "ID", flex: 5 },
    { field: "transaction_date", headerName: "Transaction Date", flex: 25 },
    { field: "description", headerName: "Description", flex: 50 },
    {
      field: "amount",
      headerName: "Amount(R)",
      flex: 25,
      renderCell: (params) => {
        let color;
        let value;
        if (params.value > 0) {
          color = theme.palette.success.main;
          value = params.value;
        } else if (params.value < 0) {
          color = theme.palette.warning.main;
          value = params.value; // Value is already negative
        } else {
          color = "inherit";
          value = "";
        }
        return <span style={{ color }}>{value}</span>;
      },
    }
  ];

  return { rows, columns };
};

export const filterTransactionsByType = (transactions, typeFilter) => {
  return transactions.filter((item) => {
    const isTypeMatch =
      (typeFilter === "money_in" && parseFloat(item.money_in) !== 0) ||
      (typeFilter === "money_out" && parseFloat(item.money_out) !== 0) ||
      typeFilter === "balance";

    return isTypeMatch;
  });
};
