import { db } from "./firebaseConfig";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  where,
  writeBatch,
  deleteDoc
} from "firebase/firestore";

// Add new bank statement to firestore
export const addStatement = async (statement) => {
  try {
    const docRef = await addDoc(collection(db, "statements"), statement);
    return docRef.id;
  } catch (error) {
    console.error("Error adding bank statement: ", error);
    throw error;
  }
};

// Subscribe to a single statement in Firestore
export const listenToStatement = (documentId, callback) => {
  const unsubscribe = onSnapshot(doc(db, "statements", documentId), (snapshot) => {
    if (snapshot.exists()) {
      const statementData = {
        id: snapshot.id, // Add document ID to the statement data
        ...snapshot.data(), // Spread other data fields
      };
      callback(statementData);
    } else {
      callback(null); // Document doesn't exist or access denied
    }
  });
  
  return unsubscribe;
};

// Subscribe to all the statement in Firestore
export const getStatements = (setStateCallback, uid) => {
  // Construct the Firestore query to filter statements by the provided UID
  const q = query(collection(db, "statements"), where("uid", "==", uid));

  // Subscribe to the filtered query and update state with the retrieved statements
  return onSnapshot(q, (querySnapshot) => {
    const statements = [];
    querySnapshot.forEach((doc) => {
      // Include document ID in each object
      const statementData = doc.data();
      statementData.id = doc.id;
      statements.push(statementData);
    });

    if (statements.length > 0) {
    }
    statements.sort((a, b) => 
      b.upload_timestamp.toDate() - a.upload_timestamp.toDate()
    );

    setStateCallback(statements);
  });
};

// Delete all statements in array by id
export const deleteStatements = async (statementIds) => {
  try {
    const batch = writeBatch(db);
    statementIds.forEach((statementId) => {
      const statementRef = doc(db, "statements", statementId);
      batch.delete(statementRef);
    });
    await batch.commit();
  } catch (error) {
    console.error("Error deleting statements:", error);
    throw error;
  }
};

// Add new message to firestore
export const addMessage = async (message) => {
  try {
    const docRef = await addDoc(collection(db, "messages"), message);
    return docRef.id;
  } catch (error) {
    console.error("Error adding message: ", error);
    throw error;
  }
};

export const getChatMessages = (setStateCallback, statementId, uid) => {
  // Construct the Firestore query to filter chat messages by chatId and uid
  const q = query(
    collection(db, "messages"),
    where("statement_id", "==", statementId),
    where("uid", "==", uid)
  );

  // Subscribe to the filtered query and update state with the retrieved messages
  return onSnapshot(q, (querySnapshot) => {
    const messages = [];
    querySnapshot.forEach((doc) => {
      // Include document ID in each object
      const messageData = doc.data();
      messageData.id = doc.id;
      messages.push(messageData);
    });
    // If messages is not empty sort the messages and set the state
    if (messages.length > 0) {
      // Sort messages by timestamp in descending order
      messages.sort((a, b) => a.timestamp - b.timestamp);
      setStateCallback(messages);
    }
  });
};

export const deleteMessageById = async (messageId) => {
  try {
    // Construct a reference to the document
    const documentRef = doc(db, "messages", messageId);
    
    // Delete the document
    await deleteDoc(documentRef);
    
  } catch (error) {
    console.error("Error deleting message:", error);
  }
};

// Delete messages by id
export const deleteMessagesByIds = async (messageIds) => {
  try {
    // Create an array to store batch delete promises
    const deletePromises = [];

    // Iterate through each messageId and create a delete promise
    messageIds.forEach((messageId) => {
      const documentRef = doc(db, "messages", messageId);
      const deletePromise = deleteDoc(documentRef);
      deletePromises.push(deletePromise);
    });

    // Execute all delete promises concurrently
    await Promise.all(deletePromises);

    console.log("Messages deleted successfully");

  } catch (error) {
    console.error("Error deleting messages");
  }
};

export const getTransactions = (setStateCallback, uid) => {
  // Construct the Firestore query to filter transactions by the provided UID
  const q = query(collection(db, "transactions"), where("uid", "==", uid));

  // Subscribe to the filtered query and update state with the retrieved transactions
  return onSnapshot(q, (querySnapshot) => {
    const transactions = [];
    querySnapshot.forEach((doc) => {
      // Include document ID in each object
      const transactionData = doc.data();
      transactionData.id = doc.id;
      
      // Ensure timestamp is a Date object or number
      if (typeof transactionData.timestamp === 'string') {
        transactionData.timestamp = new Date(transactionData.timestamp).getTime();
      } else if (transactionData.timestamp instanceof Date) {
        transactionData.timestamp = transactionData.timestamp.getTime();
      }
      
      transactions.push(transactionData);
    });

    // Sort transactions by timestamp in descending order
    transactions.sort((a, b) => b.timestamp - a.timestamp);

    // Update the state with sorted transactions
    setStateCallback(transactions);
  });
};

export const getUsageData = (setStateCallback, uid) => {
  // Construct the Firestore query to filter usage by the provided UID
  const q = query(collection(db, "usage"), where("uid", "==", uid));

  // Subscribe to the filtered query and update state with the retrieved usage transactions
  return onSnapshot(q, (querySnapshot) => {
    const usageTransactions = [];
    querySnapshot.forEach((doc) => {
      // Include document ID in each object
      const usageTransactionData = doc.data();
      usageTransactionData.id = doc.id;
      
      // Ensure timestamp is a Date object or number
      if (typeof usageTransactionData.timestamp === 'string') {
        usageTransactionData.timestamp = new Date(usageTransactionData.timestamp).getTime();
      } else if (usageTransactionData.timestamp instanceof Date) {
        usageTransactionData.timestamp = usageTransactionData.timestamp.getTime();
      }
      
      usageTransactions.push(usageTransactionData);
    });

    // Sort transactions by timestamp in descending order
    usageTransactions.sort((a, b) => b.timestamp - a.timestamp);

    // Update the state with sorted transactions
    setStateCallback(usageTransactions);
  });
};

// Subscribe to a user in Firestore
export const listenToUser = (uid, callback) => {
  const unsubscribe = onSnapshot(doc(db, "users", uid), (snapshot) => {
    if (snapshot.exists()) {
      const userData = {
        id: snapshot.id, // Add document ID to the user data
        ...snapshot.data(), // Spread other data fields
      };
      callback(userData);
    } else {
      callback(null); // Document doesn't exist or access denied
    }
  });
  
  return unsubscribe;
};


// Function to add email to waiting list
export const addEmailToWaitingList = async (bank, email) => {
  try {
    // Add email to the waiting list
    await addDoc(collection(db, "waitingList"), {
      bank,
      email,
      timestamp: new Date().toLocaleString("en-ZA", {
        timeZone: "Africa/Johannesburg",
      }),
    });
    console.log("Email added to waiting list");
  } catch (error) {
    console.error("Error adding email to waiting list: ", error);
  }
};
