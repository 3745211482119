import * as React from "react";
import {
  Button,
  TextField,
  Box,
  Link,
  Typography,
  Container,
  CssBaseline,
  Fab,
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import GoogleIcon from "../../components/google/GoogleIcon";
import { useNavigate } from "react-router-dom";
import {
  signInWithUserEmailAndPassword,
  signInWithGooglePopup,
} from "../../services/firebase/Auth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Logo from "../../components/logo/Logo";

export default function SignIn() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSignInWithEmailAndPassword = async (event) => {
    event.preventDefault(); // Prevents the default form submission behavior

    try {
      // Call signInWithUserEmailAndPassword with entered email and password
      await signInWithUserEmailAndPassword(email, password);
      navigate("/Statements");
    } catch (error) {
      // Handle error, show error message or handle in UI
      console.error("Error signing in:", error.message);
    }
  };

  const handleSignInWithGoogle = async () => {
    try {
      await signInWithGooglePopup();
      navigate("/Statements");
    } catch (error) {
      // Handle sign-in error
      console.error("Error signing in:", error.message);
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CssBaseline />

      <Logo
        sx={{
          display: { xs: "none", md: "flex" },
          mr: 1,
          fontSize: "4rem",
        }}
      />
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box
        component="form"
        onSubmit={handleSignInWithEmailAndPassword}
        sx={{ mt: 1 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                label="Password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 1, mb: 2 }}
        >
          Sign In
        </Button>
        <Fab
          type="button"
          variant="extended"
          size="small"
          sx={{ mt: 3, mb: 2, width: "100%" }}
          onClick={handleSignInWithGoogle}
        >
          <GoogleIcon />
          Sign In with Google
        </Fab>

        <Grid container>
          <Grid item xs>
            <Link href="/Reset" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="/Signup" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
