import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import useAuth from "../../services/firebase/useAuth";
import { sendAIChatMessage } from "../../api/ChatAPI";
import {
  getChatMessages,
  deleteMessagesByIds,
} from "../../services/firebase/Database";
import ChatInput from "../../components/chat/ChatInput";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import { listenToStatement } from "../../services/firebase/Database";
import ChatHeader from "../../components/chat/ChatHeader";
import ChatMessages from "../../components/chat/ChatMessages";
import DeleteDialog from "../../components/dialog/DeleteDialog";
import CustomizedSnackbar from "../../components/snackbar/CustomizedSnackbar";

const Chat = () => {
  const [query, setQuery] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const { user } = useAuth();
  const [streamedChatResponse, setStreamedChatResponse] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [selectedStatement, setSelectedStatement] = useState(null);

  // Dialog for chat clearing confimation
  const [openDialog, setOpenDialog] = React.useState(false);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    status: "success",
  });

  // Listen to a single statement using the id
  useEffect(() => {
    if (id && user) {
      const unsubscribe = listenToStatement(id, (data) => {
        setSelectedStatement(data);
      });

      return () => unsubscribe();
    }
  }, [id, user]);

  const handleUserMessageSubmit = async () => {
    if (user && selectedStatement) {
      setStreamedChatResponse("");
      const response = await sendAIChatMessage(
        query,
        setIsGenerating,
        setQuery,
        user,
        setStreamedChatResponse,
        selectedStatement
      );
    }
  };

  const handleSuggestedMessageSubmit = async (selectedQuery) => {
    if (user && selectedStatement) {
      setStreamedChatResponse("");
      const response = await sendAIChatMessage(
        selectedQuery,
        setIsGenerating,
        setQuery,
        user,
        setStreamedChatResponse,
        selectedStatement
      );
    }
  };

  useEffect(() => {
    if (selectedStatement && user) {
      const unsubscribe = getChatMessages(
        setMessages,
        selectedStatement.id,
        user.uid
      );
      setLoading(false);

      // Clean up subscription on component unmount
      return () => unsubscribe();
    }
  }, [selectedStatement, user]);

  const deleteAllMessages = async () => {
    try {
      if (!user || !selectedStatement) return; // Ensure user and selectedStatement exist

      // Gather all messageIds to delete
      const messageIdsToDelete = messages.map((message) => message.id);

      // Call deleteMessagesByIds with the array of messageIds
      await deleteMessagesByIds(messageIdsToDelete);

      // Clear messages state after deletion
      setMessages([]);

      console.log("All messages deleted successfully");
      setSnackbarConfig({
        message: "All messages deleted successfully",
        status: "success",
      });
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error deleting messages");
      setSnackbarConfig({
        message: "Error deleting messages. Try again later!",
        status: "error",
      });
      setOpenSnackbar(true);
    }
  };

  return (
    <>
    <ChatHeader
        selectedStatement={selectedStatement}
        setOpenDialog={setOpenDialog}
        messagesLength={messages.length}
      />
      {loading && <CircularProgress />}
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center contents horizontally
        justifyContent: "center", // Center contents vertically
        mb: 16,
      }}
    >

      <ChatMessages
        messages={messages}
        streamedChatResponse={streamedChatResponse}
        isGenerating={isGenerating}
      />

      <ChatInput
        query={query}
        setQuery={setQuery}
        handleSuggestedMessageSubmit={handleSuggestedMessageSubmit}
        handleUserMessageSubmit={handleUserMessageSubmit}
        loading={loading}
        messagesLength={messages.length}
      />
      <DeleteDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleDelete={deleteAllMessages}
      />

      <CustomizedSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarConfig={snackbarConfig}
      />
    </Container>
    </>
  );
};

export default Chat;
